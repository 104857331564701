import React from "react";
import "./Card.css";
const CardMobile = (props) => {
  const Data = props.Data;
  return (
    <div className="Card TextWrapper">
      {Data.MainNumber && <p className="MainNumber">{Data.MainNumber}</p>}
      {Data.subTitle && <span className="subText">{Data.subTitle}</span>}
    </div>
  );
};

export default CardMobile;
