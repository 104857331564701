import React, { useEffect, useState } from "react";
import "./BasicHeader.css";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import rightArrow from "../../assets/Vector.png";
import { GETCOLLECTION } from "../../server";

const BasicHeader = ({
  HeaderTitle,
  BottomTitle,
  ProductPage,
  PageID,
  screenWidth,
}) => {
  const location = useLocation();
  const [dropDowns, setDropDowns] = useState([]);
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  const pathnames = location.pathname.split("/").filter((x) => x);
  useEffect(() => {
    const fetchData = async () => {
      setDropDowns(await GETCOLLECTION("DropDown"));
    };
    fetchData();
  }, []);
  const findPageId = () => {
    return dropDowns.find((obj) => obj.Pages.includes(PageID));
  };

  const mainBreadCrumb = findPageId();
  return (
    <div className="BasicHeader Header">
      {screenWidth < 800 && (
        <div
          className="HelperShape"
          style={{
            backgroundColor: getDataFromLocalStorage("sidePagesHeaderColor"),
          }}
        ></div>
      )}
      <div
        className="Shape"
        style={{ bottom: screenWidth < 800 ? 0 : "unset" }}
      >
        <svg
          width="1439"
          height="282"
          viewBox="0 0 1439 282"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H1440V219.718C1440 219.718 969.177 282 688 282C406.823 282 0 219.718 0 219.718V0Z"
            fill={getDataFromLocalStorage("sidePagesHeaderColor")}
          />
        </svg>
      </div>
      <div className="Content">
        {ProductPage ? <h1>Product Details</h1> : <h1>{HeaderTitle}</h1>}
        <p>{BottomTitle}</p>
        <nav className="breadcrumbs">
          <Link style={{ textDecoration: "none" }} to="/">
            Home
          </Link>
          <img src={rightArrow} className="breadcrumb-separator" />
          <img src={rightArrow} className="breadcrumb-separator" />

          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
            return (
              <span key={to} className="breadcrumb-item">
                {index > 0 && (
                  <>
                    <img src={rightArrow} className="breadcrumb-separator" />
                    <img src={rightArrow} className="breadcrumb-separator" />
                  </>
                )}
                {ProductPage && mainBreadCrumb && (
                  <>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/${mainBreadCrumb.Link}`}
                    >
                      {mainBreadCrumb.Name}
                    </Link>
                    <img src={rightArrow} className="breadcrumb-separator" />
                    <img src={rightArrow} className="breadcrumb-separator" />
                  </>
                )}

                <Link style={{ textDecoration: "none" }} to={to}>
                  {decodeURIComponent(value).replace(/-/g, " ")}
                </Link>
              </span>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default BasicHeader;
