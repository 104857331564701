import React, { useState, useEffect, useRef } from "react";

import Input from "../../Input/Input";

const Forms = ({ Data, UpdateGeneralData, setEdited }) => {
  const [data, setData] = useState(Data);
  const handleInput = async (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleColorChange = (colorName, newColor) => {
    setData((prevData) => ({
      ...prevData,
      Colors: {
        ...prevData.Colors,
        [colorName]: newColor,
      },
    }));
  };
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);

  const handleCheckboxChange = () => {
    setData((prev) => ({
      ...prev,
      DefaultJobContactTitle: !prev.DefaultJobContactTitle,
    }));
  };
  return (
    <div className="DataEntry section4">
      <div className="boxWrapper">
        <h4 className="BoxTitle">General</h4>

        <span>
          hint: the Email that you will receive the any kind of form submits on
        </span>

        <Input
          customWidth="70%"
          label="Email"
          type="email"
          id="Email"
          name="Email"
          value={data.Email}
          onChangeFunction={handleInput}
        />
      </div>

      <button
        className="Button View"
        id="Submit"
        onClick={() => {
          UpdateGeneralData(data);
        }}
      >
        Save
      </button>
    </div>
  );
};

export default Forms;
