import React from "react";
import "./Header.css";

import VideoPlayer from "../VideoPlayer";

const Header2 = (props) => {
  return props.bg ? (
    <div className="Header Service">
      <div className="PhotoEffect">
        <img src={props.bg} />
      </div>

      <div
        className="SideContent"
        style={{ top: props.WhatToShow === "Video" ? "15%" : "0%" }}
      >
        {props.WhatToShow === "Video" ? (
          <VideoPlayer videoUrl={props.Video} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: props.HeaderData }}></div>
        )}
      </div>
    </div>
  ) : null;
};

export default Header2;
