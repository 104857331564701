import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound/NotFound";
import Template1 from "./Templates/Template1";
import Template2 from "./Templates/Template2/Template2";
import Template3 from "./Templates/Template3/Template3";
import Template4 from "./Templates/Template4/Template4";
import Template5 from "./Templates/Template5/Template5";
import Template6 from "./Templates/Template6/Template6";
import Template7 from "./Templates/Template7/Template7";
import Template9 from "./Templates/Template9/Template9";
import Template10 from "./Templates/Template10/Template10";
import Template11 from "./Templates/Template11/Template11";
import Template12 from "./Templates/Template12/Template12";
import Template13 from "./Templates/Template13/Template13";
import Template14 from "./Templates/Template14/Template14";
import BasicHeader from "./Header/BasicHeader";
const Redirect = ({
  data,
  Projects,
  Teams,
  LandingPageData,
  activeServicePage,
  setActiveServicePage,
  screenWidth,
}) => {
  const PageURL = useParams().NAME;
  const [TargetPage, setTargetPage] = useState(null);
  let ArPages = [];
  const templateComponents = {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template7,
    Template9,
    Template10,
    Template11,
    Template12,
    Template13,
    Template14,
  };

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      ArPages.push(element);
    }
  }
  useEffect(() => {
    if (ArPages.length > 0) {
      const foundPage = ArPages.find((page) => page.PageURL === PageURL);

      setTargetPage(foundPage);
    }
  }, [PageURL]);
  if (!TargetPage) {
    return <NotFound />;
  }
  const { HeaderTitle, Template, BottomTitle } = TargetPage;
  const Component = templateComponents[Template];
  return (
    <>
      <BasicHeader
        HeaderTitle={HeaderTitle}
        BottomTitle={BottomTitle}
        ProductPage={TargetPage.Template === "Template7" ? true : false}
        PageID={TargetPage.id}
        screenWidth={screenWidth}
      />
      {Component && (
        <Component
          contactUsPage={ArPages.find((page) => page.id === "34")}
          Data={TargetPage}
          FetchedProjects={Projects}
          Teams={Teams}
          LandingPageData={LandingPageData}
          activeServicePage={activeServicePage}
          setActiveServicePage={setActiveServicePage}
        />
      )}
    </>
  );
};

export default Redirect;
