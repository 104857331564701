import React, { useState, useEffect, useRef } from "react";
import { CreateToast } from "../../../../App";
import { DELETEPHOTO, UPLOADPHOTO, UPLOADVIDEO } from "../../../../server";
import Upload from "../../../../assets/upload.png";
import TipTap from "./RichTextEditor/tiptap";
import Input from "../../../Input/Input";
import VideoPlayer from "../../../VideoPlayer";
import Select from "react-select";

const Template1 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const [photoUploading, setPhotoUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUploading, setVideoUploading] = useState(false);
  const firstRender = useRef(true);
  const HeaderContent = [
    { value: "Video", label: "Video" },
    { value: "Text", label: "Text" },
  ];
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  const handleInput = async (e) => {
    const { name, value } = e.target;

    if (name === "BG") {
      setPhotoUploading(true);
      CreateToast("uploading photo", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${BackEndName}/BG.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, BG: url };
      });
      CreateToast("photo uploaded", "success", 2000);
      setPhotoUploading(false);

      return;
    } else if (name === "Video") {
      if (videoUploading) {
        CreateToast("uploading Video", "error", 2000);
        return;
      }
      setVideoUploading(true);
      CreateToast("uploading Video", "info", 10000);
      const file = e.target.files[0];
      const url = await UPLOADVIDEO(
        `/customization/SidePages/${BackEndName}/Video`,
        file,
        handleProgress
      );

      setData((prev) => {
        return { ...prev, Video: url };
      });
      setUploadProgress(0);
      CreateToast("Video uploaded", "success", 2000);

      UpdateData(BackEndName, { ...data, Video: url });
      return;
    } else if (name === "Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, Icon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const DeleteVideo = async () => {
    if (videoUploading) {
      CreateToast("Video Uploading, please wait...", "error", 2000);
      return;
    }
    CreateToast("deleting video", "info");
    await DELETEPHOTO(`/customization/SidePages/${BackEndName}/Video`);
    await UpdateData(BackEndName, { ...data, Video: "", WhatToShow: "Text" });
    setData((prev) => ({ ...prev, Video: "" }));
    CreateToast("video deleted", "success");
  };
  const handleProgress = (progress) => {
    setUploadProgress(progress);
    if (progress === 100) {
      setVideoUploading(false);
    }
  };
  const handlePostBodyChange = (value) => {
    setData((prev) => {
      return { ...prev, Content: value };
    });
  };
  const handleHeaderDataChange = (value) => {
    setData((prev) => {
      return { ...prev, HeaderData: value };
    });
  };
  return (
    <div className="DataEntry Hosting">
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>
        <div className="formItem" id="logo">
          <span>Background: </span>
          <label htmlFor="BG">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>
          <input
            type="file"
            accept="image/*"
            hidden
            id="BG"
            name="BG"
            onChange={handleInput}
          />
        </div>
        <div className="formItem">
          <span>Icon:</span>
          <label htmlFor="Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Icon"
            name="Icon"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Product Description: "
          type="text"
          id="Description"
          name="Description"
          required={true}
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Name in navigation "
          type="text"
          id="PageName"
          name="PageName"
          required={true}
          value={data.PageName}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          label="Header Title"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Header</h4>
        <div>
          <label>What To Show:</label>
          <Select
            options={HeaderContent}
            value={HeaderContent.find(
              (object) => object.value === data.WhatToShow
            )}
            onChange={(selectedOption) =>
              setData((prev) => {
                return { ...prev, WhatToShow: selectedOption.value };
              })
            }
          />
        </div>
        <div className="HeaderContent">
          <div className="video">
            <div className="UploadWrapper">
              <div className="FormItem">
                <span>Video: </span>
                <label htmlFor="Video">
                  <img
                    src={Upload}
                    style={{ width: "25px", cursor: "pointer" }}
                  />
                </label>
                <input
                  type="file"
                  accept="video/*"
                  hidden
                  id="Video"
                  name="Video"
                  onChange={handleInput}
                />
              </div>
            </div>
            {uploadProgress != 0 && (
              <div className="video-progress-bar">
                <div
                  className="video-progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
            {data.Video && (
              <div style={{ width: "500px" }}>
                <VideoPlayer videoUrl={data.Video} />

                <button className="Button Danger" onClick={DeleteVideo}>
                  Delete Video
                </button>
              </div>
            )}
          </div>
          <div className="textEditor">
            <TipTap
              editorClassName="smallEditor"
              setHTML={handleHeaderDataChange}
              OldData={data.HeaderData}
            />
          </div>
        </div>
      </div>
      <TipTap setHTML={handlePostBodyChange} OldData={data.Content} />
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            if (photoUploading) {
              CreateToast("uploading Photo,please wait...", "error", 2000);
              return;
            }
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template1;
