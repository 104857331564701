import React from "react";

const TabLink = ({
  setActivePage,
  expanded,
  activePage,
  PhotoSrc,
  Text,
  activePageTab,
}) => {
  return (
    <li
      onClick={() => {
        setActivePage(activePageTab);
      }}
      style={{ animationDelay: ".6s" }}
      className=" animate__fadeInLeft"
    >
      {expanded ? (
        <span className={`${activePage === activePageTab ? "focus" : ""} Link`}>
          {Text}
        </span>
      ) : (
        <img
          src={PhotoSrc}
          className={`${activePage === activePageTab ? "focus" : ""} Icon`}
        />
      )}
    </li>
  );
};

export default TabLink;
