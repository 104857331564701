import React, { useState, useEffect, useRef } from "react";
import { UPLOADPHOTO } from "../../../../server";
import Upload from "../../../../assets/upload.png";
import { CreateToast } from "../../../../App";
import Input from "../../../Input/Input";
const Section4 = ({
  FetchedData,
  UpdateData,
  setEdited,
  edited,
  PageOrder,
}) => {
  const [data, setData] = useState(FetchedData);
  const [photoUploaded, setPhotoUploaded] = useState(true);
  const [pageOrder, setPageOrder] = useState(PageOrder);

  const handleSelectChange = (key, value) => {
    const oldValue = pageOrder[key];
    const sectionToSwap = Object.keys(pageOrder).find(
      (key) => pageOrder[key] === value
    );
    setPageOrder((prev) => ({
      ...prev,
      [key]: value,
      [sectionToSwap]: oldValue,
    }));
  };
  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "url") {
      CreateToast("uploading photo", "info", 2000);
      const Photo = e.target.files[0];
      const URL = await UPLOADPHOTO(`/customization/Section4/BG`, Photo);
      setData((prev) => ({ ...prev, URL }));

      CreateToast("photo uploaded", "success");
      setPhotoUploaded(true);
      return;
    }
    if (name === "Thumbnail") {
      CreateToast("uploading thumbnail", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/Section4/HeaderBG.jpg`,
        Photo
      );
      setData((prev) => {
        return {
          ...prev,
          thumbnail: url,
        };
      });
      CreateToast("thumbnail uploaded", "success");
      return;
    } else if (name === "secondImage") {
      CreateToast("uploading Second Image", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/Section4/secondImage.jpg`,
        Photo
      );
      setData((prev) => {
        return {
          ...prev,
          secondImage: url,
        };
      });
      CreateToast("Second Image uploaded", "success");
      return;
    } else if (name === "thirdImage") {
      CreateToast("uploading Third Image", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/Section4/thirdImage.jpg`,
        Photo
      );
      setData((prev) => {
        return {
          ...prev,
          thirdImage: url,
        };
      });
      CreateToast("Third Image uploaded", "success");
      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const handleCheckboxChange = () => {
    setData((prev) => ({ ...prev, Show: !prev.Show }));
  };
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  return (
    <div className="DataEntry section4">
      <div className="boxWrapper">
        <h4 className="BoxTitle">General</h4>

        <div className="formItem form-check CheckBox">
          <label className="form-check-label">
            Show Section:
            <input
              className="form-check-input"
              type="checkbox"
              checked={data.Show}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <label>
          Section background Color:
          <input
            type="color"
            className="ColorPicker"
            value={data.BGColor}
            name="BGColor"
            onChange={handleInput}
          />
        </label>
        <div className="select-container">
          <span>Section Place</span>
          <select
            className="styled-select"
            value={pageOrder.Section4}
            onChange={(e) =>
              handleSelectChange("Section4", parseInt(e.target.value))
            }
          >
            <option value={1}>{1}</option>
            <option value={2}>{2}</option>
            <option value={3}>{3}</option>
            <option value={4}>{4}</option>
            <option value={5}>{5}</option>
            <option value={6}>{6}</option>
          </select>
        </div>
        {/* <div className="FormItem">
        <span>Section Photo:</span>
        <label htmlFor="thumbnailInput">
          <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
        </label>
        <input
          type="file"
          hidden
          accept="image/*"
          required
          id="thumbnailInput"
          name="url"
          onChange={handleInput}
        />
      </div> */}
        {/* <p>Section color</p>
      <input
        style={{ marginLeft: "10px" }}
        className="ColorPicker"
        type="color"
        value={data.sectionColor}
        name="sectionColor"
        onChange={handleInput}
      /> */}
        <Input
          label="Section Description that appears in dashboard"
          id="Description"
          name="Description"
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Content</h4>

        <Input
          label="Section Title"
          type="text"
          required={true}
          id="title"
          name="title"
          value={data.title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Section Tag"
          id="Tag"
          name="Tag"
          value={data.Tag}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          textarea={true}
          label="Paragraph"
          required={true}
          id="paragraph"
          name="paragraph"
          value={data.paragraph}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Button Text"
            required={true}
            id="ButtonText"
            name="ButtonText"
            value={data.ButtonText}
            onChangeFunction={handleInput}
          />
        </div>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Button Link"
            required={true}
            id="ButtonLink"
            name="ButtonLink"
            value={data.ButtonLink}
            onChangeFunction={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="UploadWrapper">
          <div className="FormItem">
            <span>First image: </span>
            <label htmlFor="thumbnailInput">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="thumbnailInput"
              name="Thumbnail"
              onChange={handleInput}
            />
          </div>
          <div className="FormItem">
            <span>Bottom Small image: </span>
            <label htmlFor="secondImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="secondImage"
              name="secondImage"
              onChange={handleInput}
            />
          </div>
          <div className="FormItem">
            <span>Top Small image: </span>
            <label htmlFor="thirdImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="thirdImage"
              name="thirdImage"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>

      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            if (!photoUploaded) {
              CreateToast("photo uploading please wait", "error");
              return;
            }
            setEdited(false);
            UpdateData("PageOrder", pageOrder);
            UpdateData("Section4", data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Section4;
