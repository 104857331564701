import React, { useEffect, useState } from "react";
import { CreateToast } from "../../App";
import {
  BackUpDate,
  EMPTYFOLDER,
  GETCOLLECTION,
  GETCOLLECTIONFORBACKUP,
  GETDOC,
  UPLOADPHOTO,
  decrypt,
} from "../../server";
import "./Dashboard.css";
import WebSettings from "./Customization/WebSettings";
import Articles from "./Articles/Articles";
import Categories from "./Categories/Categories";
import Users from "./Users/Users";
import NotFound from "../NotFound/NotFound";
import ArticleBuilder from "../Blog/ArticleBuilder/ArticleBuilder";
import Settings from "../Settings/Settings";
import DropDowns from "./DropDowns/DropDowns";
import DropDownsIcon from "../../assets/Dropdowns.png";
import logout from "../../assets/logout.png";
import backup from "../../assets/backup.png";
import customization from "../../assets/customization.png";
import userIcon from "../../assets/user.png";
import categoriesIcon from "../../assets/categories.png";
import ArticlesIcon from "../../assets/Articles.png";
import addArticle from "../../assets/addArticle.png";
import UsersIcon from "../../assets/Users.png";
import expand from "../../assets/Expand.png";
import Shrink from "../../assets/Shrink.png";
import forms from "../../assets/forms.png";
import FormSubmits from "./FormSubmits/FormSubmits";
import Backup from "./Backup/Backup";
import Jobs from "./Jobs/Jobs";
import TabLink from "./TabLink";
import { getDoc } from "firebase/firestore";
const Dashboard = () => {
  const [greeting, setGreeting] = useState("");
  const [activePage, setActivePage] = useState("Users");
  const [expanded, setExpanded] = useState(true);
  const [categories, setCategories] = useState([]);
  const [customizationPage, setCustomizationPage] = useState("Nav1");
  const [ActiveUser, setActiveUser] = useState(
    sessionStorage.getItem("activeUser")
      ? decrypt(JSON.parse(sessionStorage.getItem("activeUser")).id)
      : ""
  );
  const [authorized, setAuthorized] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const CheckInfo = (res) => {
    const vals = Object.keys(res).map(function (key) {
      return res[key];
    });
    for (let index = 0; index < vals.length; index++) {
      if (typeof vals[index] !== "boolean") {
        if (typeof vals[index] !== "object")
          if (vals[index] !== 0) {
            if (!vals[index]) {
              CreateToast(
                `your Profile is incomplete! go to ${
                  res.admin ? "Admin Profile" : "settings"
                } to complete it`,
                "warning"
              );
              return;
            }
          }
      }
    }
  };
  useEffect(() => {
    const checkData = async () => {
      setCategories(await GETCOLLECTION("categories"));
      const FetchUser = await GETDOC("users", ActiveUser);
      setAuthorized(
        FetchUser.Role === "Admin" || FetchUser.Role === "Owner" ? true : false
      );
      setActiveUser(FetchUser);
      CheckInfo(FetchUser);
    };
    ActiveUser && checkData();
    if (new Date().getHours() < 12) setGreeting("Good morning");
    else if (new Date().getHours() < 18) setGreeting("Good afternoon");
    else setGreeting("Good evening");
  }, []);

  const logOut = async () => {
    sessionStorage.clear();
    window.location.href = "/";
  };
  const convertFirebaseTimestampToDate = (firebaseTimestamp) => {
    const { seconds, nanoseconds } = firebaseTimestamp;
    // Create a JavaScript Date object from Firebase timestamp
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  };
  const hasWeekPassed = async () => {
    const LastBackup = await GETDOC("BackUpDate", "BackUpDate");
    if (LastBackup === "Error") {
      return true;
    }

    const date = convertFirebaseTimestampToDate(LastBackup.BackUpDate);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - date;

    // Convert milliseconds to weeks
    const diffInWeeks = diffInMs / (1000 * 60 * 60 * 24 * 7);

    // Check if the difference is greater than or equal to 1 week
    return diffInWeeks >= 1;
  };
  const createBackupData = async (name) => {
    try {
      const collectionData = await GETCOLLECTIONFORBACKUP(name);

      if (!collectionData || !Array.isArray(collectionData)) {
        throw new Error(`Expected an array but got ${typeof collectionData}`);
      }

      const allCollections = collectionData.map((doc) => ({
        id: doc.id,
        data: doc,
      }));

      return JSON.stringify(allCollections, null, 2);
    } catch (error) {
      console.error(`Error creating backup data for ${name}:`, error);
      throw error;
    }
  };
  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const uploadBackup = async (fileName, data) => {
    const blob = new Blob([data], { type: "application/json" });
    const path = `backups/${fileName}`; // Define the path in Firebase Storage
    const url = await UPLOADPHOTO(path, blob);
    return url;
  };
  const backupAndUpload = async () => {
    const weakPassed = await hasWeekPassed();
    if (weakPassed || weakPassed == undefined) {
      CreateToast(`Backing up data`);

      try {
        const collectionNames = [
          "Articles",
          "Blog",
          "DropDown",
          "Projects",
          "Team",
          "customization",
          "categories",
        ];
        await EMPTYFOLDER("/backups");
        for (let name of collectionNames) {
          const backupData = await createBackupData(name);
          const currentDate = formatDate();
          const fileName = `${name}-backup-${currentDate}.json`;
          await uploadBackup(fileName, backupData);
        }
        await BackUpDate();
        CreateToast(`Backup uploaded successfully`, "success");
      } catch (error) {
        console.error("Error creating or uploading backup:", error);
        CreateToast("Error creating or uploading backup", "error");
      }
    }
  };
  useEffect(() => {
    backupAndUpload();
  }, []);
  return (
    <div className="Dashboard">
      {width < 800 ? (
        <h1 className="Reject">
          Sorry you must be on a larger screen to view this page
        </h1>
      ) : authorized ? (
        <>
          <div className={`${expanded ? "Expanded" : ""} SideBar`}>
            <h3 className="Greet">
              {expanded && <p className=" animate__fadeInDown">{greeting}</p>}
              <span
                className="  animate__fadeInUp"
                style={{ animationDelay: ".3s" }}
              >
                {ActiveUser.Username}
              </span>
            </h3>
            <ul className="BTNList">
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Users"
                PhotoSrc={UsersIcon}
                activePageTab={"Users"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Articles"
                PhotoSrc={ArticlesIcon}
                activePageTab={"Articles"}
              />
              {/* <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Jobs"
                PhotoSrc={JobsIcon}
                activePageTab={"Jobs"}
              /> */}
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Create An Article"
                PhotoSrc={addArticle}
                activePageTab={"Create"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Articles Categories"
                PhotoSrc={categoriesIcon}
                activePageTab={"Categories"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Admin Profile"
                PhotoSrc={userIcon}
                activePageTab={"Profile"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Dropdowns"
                PhotoSrc={DropDownsIcon}
                activePageTab={"Dropdowns"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-NewsLetters"
                PhotoSrc={forms}
                activePageTab={"forms"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Customization"
                PhotoSrc={customization}
                activePageTab={"Customization"}
              />
              <TabLink
                setActivePage={setActivePage}
                expanded={expanded}
                Text="-Backup"
                PhotoSrc={backup}
                activePageTab={"Backup"}
              />

              <li
                onClick={logOut}
                style={{ animationDelay: "1s", marginTop: "auto" }}
                className={` animate__fadeInLeft`}
              >
                {expanded ? (
                  <span className="Link">-Logout</span>
                ) : (
                  <img src={logout} className="Icon" />
                )}
              </li>
            </ul>

            <img
              onClick={() => {
                setExpanded((prev) => !prev);
              }}
              className={` animate__fadeInLeft`}
              style={{
                margin: " 0px auto",
                width: "25px",
                cursor: "pointer",
                animationDelay: "1.1s",
              }}
              src={expanded ? Shrink : expand}
            />
          </div>
          <div className="Page">
            {activePage === "Users" && <Users />}
            {activePage === "Create" && (
              <ArticleBuilder Categories={categories} />
            )}
            {activePage === "forms" && <FormSubmits />}
            {activePage === "Customization" && (
              <WebSettings
                customizationPage={customizationPage}
                SetCustomizationPage={setCustomizationPage}
              />
            )}
            {activePage === "Categories" && <Categories />}
            {/* {activePage === "Jobs" && <Jobs />} */}
            {activePage === "Profile" && <Settings />}
            {activePage === "Backup" && <Backup />}
            {activePage === "Dropdowns" && (
              <DropDowns
                setActivePageDash={setActivePage}
                SetCustomizationPage={setCustomizationPage}
              />
            )}
            {activePage === "Articles" && <Articles />}
          </div>
        </>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default Dashboard;
