import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="NotFound">
      <h1>404</h1>
      <p>that page doesn't exist or unavailable right now</p>
      <Link to={"/"}>
        <button>go back to home</button>
      </Link>
    </div>
  );
};

export default NotFound;
