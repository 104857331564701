import React, { useRef, useState, useEffect } from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import useElementVisibility from "../../hooks/useElementVisibility";
import CountUp from "react-countup";

const Card = (props) => {
  const { Data } = props;
  const ref = useRef(null);

  const isVisible = useElementVisibility(ref);
  return (
    <Link style={{ textDecoration: "none" }} to={Data.Link}>
      <div
        style={{ cursor: Data.Link ? "pointer" : "" }}
        className="Card"
        data-aos="fade-down"
        data-aos-delay={props.index * 100}
      >
        <div className="TopText" ref={ref}>
          {Data.subTitle && <span className="subText">{Data.subTitle}</span>}
          <div className="Line"></div>
          {Data.MainNumber && isVisible && (
            <CountUp
              className="MainNumber"
              start={0}
              end={Data.MainNumber}
              duration={4}
              delay={0}
              useEasing={true}
              useGrouping={true}
            />
          )}
          <span className="MainNumber" style={{ marginLeft: "5px" }}>
            +
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Card;
