import React, { useEffect, useState } from "react";
import { GETDOC } from "../../../server";
import Loading from "../../Loading/Loading";
import sortBy from "sort-by";
import "./Template13.css";
import { Link } from "react-router-dom";
const Template13 = ({ Data }) => {
  const [loading, setLoading] = useState(true);
  const [Products, setProducts] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const Data = await GETDOC("customization", "Sidepages");
      const filteredData = Object.entries(Data)
        .filter(([key, value]) => value.PageType === "Product")
        .map(([key, value]) => value);
      setProducts(filteredData.sort(sortBy("id")));
      setLoading(false);
    };
    getData();
  }, []);
  const RenderProducts = Products.map((Product) => {
    if (Product.PageURL) {
      return (
        <div className="ProductCard" key={Product.id}>
          <h3>{Product.HeaderTitle}</h3>
          <div className="Line"></div>
          <p className="subTitle">{Product.BottomTitle}</p>
          <p className="Description">{Product.Description}</p>
          <Link className="Link" to={`/${Product.PageURL}`}>
            Read More
          </Link>
        </div>
      );
    }
  });
  return (
    <div className="Template13">
      <Loading loading={loading} />
      {Data.PageTitle && <h1>{Data.PageTitle} </h1>}
      <div className="Products-wrapper">{RenderProducts}</div>
    </div>
  );
};

export default Template13;
