import React, { useState, useEffect, useRef } from "react";
import { CreateToast } from "../../../../App";
import sortBy from "sort-by";
import DataTable from "react-data-table-component";
import MyModal from "../../../PopUps/Confirm/Confirm";
import Input from "../../../Input/Input";
import TipTap from "./RichTextEditor/tiptap";
import Upload from "../../../../assets/upload.png";
import { UPLOADPHOTO } from "../../../../server";

const Template10 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const [NewCard, setNewCard] = useState({
    Content: "",
    Name: "",
    id: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handlePrimaryAction = () => {
    if (data.Sec3Elements?.length === 5) {
      CreateToast("max Cards Reached", "error");
      return;
    }
    const FoundName = data.Sec3Elements?.some((element) => {
      return element.Name === NewCard.Name;
    });
    if (FoundName) {
      CreateToast("Name is taken by another card", "error");
      return;
    }
    setData((prev) => {
      if (prev.Sec3Elements) {
        return {
          ...prev,
          Sec3Elements: [...prev.Sec3Elements, NewCard],
        };
      } else {
        return {
          ...prev,
          Sec3Elements: [NewCard],
        };
      }
    });

    setNewCard({
      Content: "",
      Name: "",
      id: "",
    });
    handleCloseModal();
  };
  const handlePostBodyChange = (value, Target) => {
    if (Target === "NewCard") {
      setNewCard((prev) => {
        return { ...prev, Content: value };
      });
      return;
    }
    let valueToChange;
    switch (Target) {
      case "Section1":
        valueToChange = "Sec1Body";
        break;
      case "Section2":
        valueToChange = "Sec2Body";
        break;
      case "Section4":
        valueToChange = "Sec4Body";
        break;
      case "Section5":
        valueToChange = "Sec5Body";
        break;
      case "Section6":
        valueToChange = "Sec6Body";
        break;
      case "Section7":
        valueToChange = "Sec7Body";
        break;

      default:
        break;
    }
    setData((prev) => {
      return { ...prev, [valueToChange]: value };
    });
  };
  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, Icon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const DeleteCard = (id) => {
    const NewCards = data.Sec3Elements?.filter((Card) => {
      return Card.id !== id;
    });

    setData((prev) => {
      return { ...prev, Sec3Elements: NewCards };
    });
  };
  const CardsData = data.Sec3Elements?.map((Card) => {
    return {
      id: Card.id,
      title: Card.Name,
      Description: Card.Content,
      Options: (
        <button
          className="Button Danger"
          onClick={() => {
            DeleteCard(Card.id);
          }}
        >
          Delete
        </button>
      ),
    };
  });
  const CardsColumns = [
    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => (
        <div
          className="text-wrap"
          dangerouslySetInnerHTML={{ __html: row.Description }}
        ></div>
      ),
      sortable: true,
      center: true,
    },

    {
      name: "Options",
      selector: (row) => row.Options,
      center: true,
      width: "200px",
    },
  ];
  useEffect(() => {
    let id;
    if (data.Sec3Elements?.length === 0 || !data.Sec3Elements) {
      id = 1;
    } else {
      data.Sec3Elements?.sort(sortBy("id"));
      data.Sec3Elements?.forEach((category) => {
        id = +category.id + 1;
      });
    }
    setNewCard((prev) => {
      return { ...prev, id };
    });
  }, []);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  return (
    <div className="DataEntry Hosting">
      {showModal && (
        <MyModal
          className="Confirm PricingModal"
          show={showModal}
          handleClose={handleCloseModal}
          title="add Card"
          primaryButtonText={`add`}
          handlePrimaryAction={handlePrimaryAction}
        >
          <>
            <Input
              label="Tab Name:"
              type="text"
              id="Name"
              name="Name"
              value={NewCard.Name}
              onChangeFunction={(event) => {
                setNewCard((prev) => {
                  return { ...prev, [event.target.name]: event.target.value };
                });
              }}
            />

            <TipTap
              setHTML={(value) => {
                handlePostBodyChange(value, "NewCard");
              }}
              OldData={NewCard.Content}
            />
          </>
        </MyModal>
      )}
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="formItem">
          <span>Icon:</span>
          <label htmlFor="Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Icon"
            name="Icon"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL:"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          label="Page Name in navigation :"
          type="text"
          id="PageName"
          name="PageName"
          value={data.PageName}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Description: "
          type="text"
          id="Description"
          name="Description"
          required={true}
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Header Title:"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Bottom Title:"
            type="text"
            required={true}
            id="BottomTitle"
            name="BottomTitle"
            value={data.BottomTitle}
            onChangeFunction={handleInput}
            style={{ color: data.BottomColor }}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Cards</h4>

        <button className="Button View" onClick={handleShowModal}>
          AddCard
        </button>
        <DataTable
          className="Table  animate__fadeIn"
          style={{ animationDelay: ".4s" }}
          theme="light"
          highlightOnHover
          columns={CardsColumns}
          data={CardsData}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section one</h4>
        <Input
          label="Section one Title:"
          type="text"
          id="Sec1Title"
          name="Sec1Title"
          value={data.Sec1Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section1");
          }}
          OldData={data.Sec1Body}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section Two</h4>

        <Input
          label="Section Two Title:"
          type="text"
          id="Sec2Title"
          name="Sec2Title"
          value={data.Sec2Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section2");
          }}
          OldData={data.Sec2Body}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section Three</h4>

        <Input
          label="Section Three Title:"
          type="text"
          id="Sec4Title"
          name="Sec4Title"
          value={data.Sec4Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          label="Section Three Sub Title:"
          type="text"
          id="Sec4Subtitle"
          name="Sec4Subtitle"
          value={data.Sec4Subtitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section4");
          }}
          oldData={data.Sec4Body}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section Four</h4>

        <Input
          label="Section Four Title:"
          type="text"
          id="Sec5Title"
          name="Sec5Title"
          value={data.Sec5Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section5");
          }}
          oldData={data.Sec5Body}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section Five</h4>

        <Input
          label="Section Five Title:"
          type="text"
          id="Sec6Title"
          name="Sec6Title"
          value={data.Sec6Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section6");
          }}
          oldData={data.Sec6Body}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Section Six</h4>

        <Input
          label="Section Six Title:"
          type="text"
          id="Sec7Title"
          name="Sec7Title"
          value={data.Sec7Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <TipTap
          setHTML={(value) => {
            handlePostBodyChange(value, "Section7");
          }}
          oldData={data.Sec7Body}
        />
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template10;
