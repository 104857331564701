import React, { useState, useEffect } from "react";
import { CreateToast } from "../../../../App";
import Upload from "../../../../assets/upload.png";
import {
  UPLOADPHOTO,
  GETCOLLECTION,
  SETDOC,
  DELETEDOC,
} from "../../../../server";
import DataTable from "react-data-table-component";
import sort from "../../../../assets/sort.png";

import sortBy from "sort-by";
import MyModal from "../../../PopUps/Confirm/Confirm";
import Input from "../../../Input/Input";

const Template8 = ({ BackEndName }) => {
  const [photoUploaded, setPhotoUploaded] = useState(true);

  const [newPerson, setNewPerson] = useState({
    LinkedIn: "",
    Location: "",
    Role: "",
    Whatsapp: "",
    id: "",
    image: "",
    name: "",
    overview: "",
  });
  const [disableReorder, setDisableReorder] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Team, setTeam] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handlePrimaryAction = async () => {
    if (!validateLink(newPerson.LinkedIn)) {
      CreateToast("LinkedIn is not a valid Link", "error");
      return;
    }

    if (!photoUploaded) {
      CreateToast("photo uploading please wait", "error");
      return;
    }
    handleCloseModal();
    CreateToast("Adding...", "info");
    let Order = 1; // Default order
    console.log(newPerson);

    if (Team.length > 0) {
      Order = Math.max(...Team.map((member) => member.Order)) + 1;
    }
    await SETDOC("Team", newPerson.id, { ...newPerson, Order }, true);

    setTeam(await GETCOLLECTION("Team"));

    CreateToast("Person Added", "success");
    setNewPerson({
      LinkedIn: "",
      Location: "",
      Role: "",
      Whatsapp: "",
      id: "",
      Order: 1,
      image: "",
      name: "",
      overview: "",
    });
    setPhotoUploaded(true);
  };
  const HandleNewInput = async (e) => {
    const { name, value } = e.target;

    if (name === "Photo") {
      setPhotoUploaded(false);
      CreateToast("uploading photo", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${BackEndName}/${newPerson.id}/Photo.jpg`,
        Photo
      );
      setNewPerson((prev) => {
        return { ...prev, image: url };
      });
      CreateToast("photo uploaded", "success", 2000);
      setPhotoUploaded(true);

      return;
    } else {
      setNewPerson((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const validateLink = (link) => {
    const linkPattern = /^(http|https):\/\/([\w.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return linkPattern.test(link);
  };
  useEffect(() => {
    const GetID = async () => {
      let id;
      const Team = await GETCOLLECTION("Team");
      if (Team.length === 0) {
        id = 1;
      } else {
        Team.sort(sortBy("id"));
        Team.forEach((Member) => {
          id = +Member.id + 1;
        });
      }
      setNewPerson((prev) => {
        return { ...prev, id };
      });
    };
    GetID();
  }, [Team]);
  useEffect(() => {
    const FetchData = async () => {
      setTeam(await GETCOLLECTION("Team"));
    };
    FetchData();
  }, []);
  const DeletePerson = async (id) => {
    CreateToast(`Deleting...`, "info");
    await DELETEDOC("Team", id);
    const Team = await GETCOLLECTION("Team");
    setTeam(Team);
    CreateToast("deleted", "success");
  };
  const moveUp = async (id) => {
    CreateToast("moving");
    setDisableReorder(true);
    const newTeam = [...Team];
    newTeam.sort(sortBy("Order")); // Ensure the team is sorted by Order

    // Find the current member by ID
    const currentIndex = newTeam.findIndex((member) => member.id === id);

    if (currentIndex === 0) return; // First item cannot be moved up

    const currentMember = newTeam[currentIndex]; // Get the current member
    const previousMember = newTeam[currentIndex - 1]; // Get the previous member

    // Swap their Order values
    [currentMember.Order, previousMember.Order] = [
      previousMember.Order,
      currentMember.Order,
    ];

    // Update Firebase with the new order values
    await SETDOC("Team", currentMember.id, currentMember);
    await SETDOC("Team", previousMember.id, previousMember);

    // Update the team in the local state
    setTeam(newTeam);
    setDisableReorder(false);
    CreateToast("moved", "success");
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => <div className="text-wrap"> {row.Name}</div>,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
      center: true,
    },
    {
      name: "LinkedIn",
      selector: (row) => row.LinkedIn,
      sortable: true,
      center: true,
    },
    {
      name: "Whatsapp",
      selector: (row) => row.Whatsapp,
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: (row) => row.Role,
      sortable: true,
      center: true,
    },
    {
      name: "Overview",
      selector: (row) => <div className="text-wrap">{row.Overview}</div>,
      sortable: true,
      center: true,
      width: "300px",
    },
    {
      name: "Photo",
      selector: (row) => row.Photo,
      sortable: true,
      center: true,
      width: "300px",
    },

    {
      id: "Place",
      name: "Place",
      selector: (row) => row.Place,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Options",
      selector: (row) => row.Options,
      center: true,
      width: "300px",
    },
  ];
  const tableData = Team.map((team, Index) => {
    return {
      id: team.id,
      Place: team.Order,
      Name: team.name,
      Location: team.Location,
      LinkedIn: team.LinkedIn,
      Whatsapp: team.Whatsapp,
      Role: team.Role,
      Overview: team.overview,
      Photo: (
        <img style={{ maxWidth: "200px", margin: "20px 0" }} src={team.image} />
      ),
      Options: (
        <div className="Button-wrapper">
          <button
            className="Button Sort"
            disabled={disableReorder}
            onClick={() => {
              moveUp(team.id);
            }}
          >
            <img src={sort} />
          </button>
          <button
            className="Button View"
            onClick={() => {
              window.location.href = `/Dashboard/Member/${team.id}`;
            }}
          >
            Edit
          </button>
          <button
            className="Button Danger"
            onClick={() => {
              DeletePerson(team.id);
            }}
          >
            Delete
          </button>
        </div>
      ),
    };
  });
  return (
    <div className="DataEntry Hosting">
      {showModal && (
        <MyModal
          className="Confirm PricingModal"
          show={showModal}
          handleClose={handleCloseModal}
          title="Add Person"
          primaryButtonText={`Add`}
          handlePrimaryAction={handlePrimaryAction}
        >
          <>
            <Input
              label="Name:"
              type="text"
              required
              id="name"
              autoComplete="false"
              name="name"
              value={newPerson.name}
              onChangeFunction={HandleNewInput}
            />

            <Input
              label="Location:"
              type="text"
              required
              id="Location"
              autoComplete="false"
              name="Location"
              value={newPerson.Location}
              onChangeFunction={HandleNewInput}
            />

            <Input
              label="LinkedIn:"
              type="url"
              required
              id="LinkedIn"
              autoComplete="false"
              name="LinkedIn"
              value={newPerson.LinkedIn}
              onChangeFunction={HandleNewInput}
            />

            <Input
              label="Whatsapp:"
              type="number"
              required
              id="Whatsapp"
              autoComplete="false"
              name="Whatsapp"
              value={newPerson.Whatsapp}
              onChangeFunction={HandleNewInput}
            />

            <Input
              label="Role:"
              type="text"
              required
              id="Role"
              autoComplete="false"
              name="Role"
              value={newPerson.Role}
              onChangeFunction={HandleNewInput}
            />

            <Input
              textarea={true}
              label="Overview:"
              type="textarea"
              required
              id="overview"
              autoComplete="false"
              name="overview"
              value={newPerson.overview}
              onChangeFunction={HandleNewInput}
            />

            <div className="formItem" id="logo">
              <span>Picture:</span>
              <label htmlFor="thumbnailInput">
                <img
                  src={Upload}
                  style={{ width: "25px", cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                hidden
                required
                id="thumbnailInput"
                name="Photo"
                onChange={HandleNewInput}
              />
            </div>
          </>
        </MyModal>
      )}
      <button className="Button View" onClick={handleShowModal}>
        Add Person
      </button>
      <DataTable
        className="Table  animate__fadeIn"
        style={{ animationDelay: ".4s" }}
        theme="light"
        highlightOnHover
        defaultSortFieldId="Place"
        columns={tableColumns}
        data={tableData}
      />
    </div>
  );
};

export default Template8;
