import React from "react";
import "./PriceCard.css";
import { Link } from "react-router-dom";
const PriceCard = (props) => {
  const RenderPros = props.data.pros.map((pros) => {
    const highlight = props.highLightedID === pros.id ? true : false;
    return (
      <p
        className={`${pros.id} ${highlight ? "highLight" : ""}`}
        key={pros.id}
        onMouseEnter={() => {
          props.changeHighlight(pros.id);
        }}
        onMouseLeave={() => {
          props.changeHighlight(null);
        }}
      >
        {pros.text}
      </p>
    );
  });
  return (
    <div className="PriceCard">
      <div className="TopPart">
        {props.data.title && <p className="Plan">{props.data.title}</p>}
        <div className="Price-wrapper">
          {props.data.Price && <h4>{props.data.Price} </h4>}
          {props.data.PerMonth && <span> {"  "} / Month</span>}
        </div>
        {props.data.description && (
          <p className="PlanDesc">{props.data.description}</p>
        )}
      </div>
      <div className="BottomPart">{RenderPros}</div>

      <Link
        style={{ textDecoration: "none", width: "100%" }}
        to={props.contactUsPage ? `/${props.contactUsPage?.PageURL}` : ""}
      >
        <button className="Button"> Sign Up</button>
      </Link>
    </div>
  );
};

export default PriceCard;
