import React, { useEffect, useState } from "react";
import { GETDOC } from "../../../server";
import sortBy from "sort-by";
import Loading from "../../Loading/Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

import Header2 from "../../Header/Header2";
import Template1 from "./../Template1";
import "./Template12.css";

const Template12 = ({ Data, activeServicePage }) => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [activeTab, setActiveTab] = useState(activeServicePage);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setActiveTab(services.find((Page) => Page.id == activeServicePage.id));
  }, [activeServicePage]);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    const getData = async () => {
      const Data = await GETDOC("customization", "Sidepages");
      const filteredData = Object.entries(Data)
        .filter(([key, value]) => value.PageType === "Service")
        .map(([key, value]) => value);
      setServices(filteredData.sort(sortBy("id")));
      setActiveTab(
        activeServicePage
          ? filteredData.find((Page) => Page.id == activeServicePage?.id)
          : filteredData.sort(sortBy("id"))[0]
      );
      setLoading(false);
    };
    getData();
  }, []);
  const RenderServices = services?.map((service) => {
    if (service.PageURL) {
      return (
        <SwiperSlide key={service.id}>
          <div
            onClick={() => {
              setActiveTab(service);
            }}
            className={`serviceLink ${
              activeTab?.id === service.id ? "Active" : ""
            }`}
          >
            <p>{service.PageName}</p>
          </div>
        </SwiperSlide>
      );
    }
  });
  return (
    <div className="Template12" style={{ gap: "30px" }}>
      <Loading loading={loading} />

      {Data.PageTitle && (
        <h1 style={{ margin: "40px auto", textAlign: "center" }}>
          {Data.PageTitle}
        </h1>
      )}
      <div className="Navigator" style={{ width: "85%", margin: "auto" }}>
        <Swiper
          freeMode={true}
          loop={true}
          slidesPerView={
            services.filter((service) => service.PageURL)?.length > 1 ? 2 : 1
          }
          spaceBetween={10}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            900: {
              slidesPerView:
                services.filter((service) => service.PageURL)?.length > 1
                  ? 2
                  : 1,
              spaceBetween: 20,
            },
            250: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
          className="mySwiper"
        >
          {RenderServices}
        </Swiper>
      </div>
      {activeTab && (
        <>
          <Header2
            screenWidth={width}
            title={activeTab.HeaderTitle}
            bg={activeTab.BG}
            TopTitle={activeTab.TopTitle}
            TopColor={activeTab.TopColor}
            BottomTitle={activeTab.BottomTitle}
            BottomColor={activeTab.BottomColor}
            Font={activeTab.Font}
            Color={activeTab.Color}
            HeaderData={activeTab.HeaderData}
            WhatToShow={activeTab.WhatToShow}
            Video={activeTab.Video}
          />
          <Template1 Data={activeTab} />
        </>
      )}
    </div>
  );
};

export default Template12;
