import React, { useRef, useState } from "react";
import "./Section7.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";
import rightArrow from "../../../assets/gradientArrow.png";
import VideoPlayer from "../../VideoPlayer";
import { Link } from "react-router-dom";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import useElementVisibility from "../../hooks/useElementVisibility";
const Section7 = (props) => {
  const [activeTab, setActiveTab] = useState(props.ServerData.Slider[0]);
  const ref = useRef(null);

  const isVisible = useElementVisibility(ref);

  const buttonRef = useRef(null);
  const isVisible2 = useElementVisibility(buttonRef);
  const RenderNav = props.ServerData.Slider.map((skill, index) => {
    return (
      <SwiperSlide key={skill.ID}>
        <ProductCard
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          skill={skill}
        />
      </SwiperSlide>
    );
  });

  const videoExtensions = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "mpg",
    "m4v",
    "3gp",
    "ogg",
    "swf",
    "vob",
    "rm",
    "divx",
  ];
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <section
      className="Section7"
      style={{
        backgroundColor: props.ServerData.BGColor && props.ServerData.BGColor,
      }}
    >
      {props.ServerData.Tag && (
        <div
          className="Tag"
          style={{
            backgroundColor:
              getDataFromLocalStorage("TagBG") &&
              getDataFromLocalStorage("TagBG"),
            margin: "16px auto",
          }}
        >
          <span>{props.ServerData.Tag}</span>
        </div>
      )}
      {props.ServerData.Title && <h2>{props.ServerData.Title}</h2>}
      <div className="ProductWrapper">
        <ul className="Navigator" style={{ overflow: "hidden" }}>
          <Swiper
            data-aos="fade-up"
            className="Swiper-Skills"
            data-aos-duration="1000"
            freeMode={false}
            loop={true}
            slidesPerView={5}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              900: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              250: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
            modules={[Pagination]}
          >
            {RenderNav}
          </Swiper>
        </ul>
      </div>
      {activeTab && (
        <div className="Display">
          <div className="Left">
            <h4 data-aos="fade-right">{activeTab.Name}</h4>
            <div
              data-aos="fade-right"
              data-aos-delay="300"
              dangerouslySetInnerHTML={{ __html: activeTab.Description }}
              className="content"
            ></div>
            <Link style={{ textDecoration: "none" }} to={activeTab.Link}>
              <button
                className={`Button Empty  ${
                  isVisible2
                    ? "animate__animated animate__slow  animate__fadeInLeft"
                    : ""
                }`}
                ref={buttonRef}
              >
                <span>Read more</span>
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1.5L6.5 6.5L1.5 11.5"
                    stroke="url(#paint0_linear_264_60)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />

                  <defs>
                    <linearGradient
                      id="paint0_linear_264_60"
                      x1="0.431452"
                      y1="6.61111"
                      x2="6.39919"
                      y2="6.61111"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop
                        stopColor={getDataFromLocalStorage("gradientColor1")}
                      />
                      <stop
                        offset="1"
                        stopColor={getDataFromLocalStorage("gradientColor2")}
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </Link>
          </div>
          <div className="Right PhotoEffect" data-aos="fade-in">
            {videoExtensions.find(
              (extension) => extension === activeTab.extension
            ) ? (
              <VideoPlayer videoUrl={activeTab.URL} />
            ) : (
              <img src={activeTab.URL} />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Section7;
