import React, { useRef, useState } from "react";
import "./AboutUsCards.css";
import useElementVisibility from "../../hooks/useElementVisibility";

const AboutUsCards = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);

  const isVisible = useElementVisibility(ref);
  return (
    <div
      ref={ref}
      className={`WhyUs-Card ${isHovered ? "active" : ""} ${
        isVisible ? "animate__animated animate__slow  animate__fadeIn" : ""
      }`}
      style={{
        cursor: props.Data.Link ? "pointer" : "",
        animationDelay: ".2s",
      }}
      onClick={() => {
        props.Data.Link && (window.location.href = props.Data.Link);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="cardBG"></div>
      {props.Data.subTitle && <h3>{props.Data.subTitle}</h3>}
      {props.Data.Description && <span>{props.Data.Description}</span>}
    </div>
  );
};

export default AboutUsCards;
