import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sortBy from "sort-by";
import { GETCOLLECTION } from "../../server";
import ArrowDown from "../../assets/arrowDown.png";
import ArrowLeft from "../../assets/nav bar table arrow.png";

const NavDesktop = ({ Tabs, setActiveServicePage }) => {
  const location = useLocation();
  const [ActivePage, setActivePage] = useState("");
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  useEffect(() => {
    setActivePage(location.pathname.replace(/%20/g, " ").substring(1));
  }, [location]);
  const [TabsAR, setTabsAR] = useState([]);
  const [dropDowns, setDropDowns] = useState([]);
  const [nonList, setNonList] = useState([]);
  useEffect(() => {
    if (Tabs) {
      let ArPages = [];
      let TempNon = [];

      for (const key in Tabs) {
        if (Object.hasOwnProperty.call(Tabs, key)) {
          const element = Tabs[key];

          ArPages.push({
            Name: key,
            PageName: element.PageName,
            PageURL: element.PageURL,
            DropDownFamily: element.DropDownFamily,
            id: element.id,
            Description: element.Description,
            Icon: element.Icon,
          });
        }
      }
      setTabsAR(ArPages);
      TempNon = ArPages.filter((Page) => {
        return Page.DropDownFamily === "0";
      });
      setNonList(TempNon.sort(sortBy("id")));
    }
  }, [Tabs]);
  const RenderNonList = nonList?.map((link) => {
    if (
      link.id === "16" ||
      link.id === "17" ||
      link.id === "33" ||
      link.id === "34" ||
      link.id === "32"
    ) {
      return;
    }
    if (link.PageURL) {
      return (
        <li key={link.PageURL}>
          <Link
            className={ActivePage === link.PageURL ? "ActiveLink" : ""}
            onClick={() => {
              setActivePage(link.PageURL);
            }}
            style={{ textDecoration: "none" }}
            to={`/${link.PageURL}`}
          >
            {link.PageName}
          </Link>
        </li>
      );
    }
  });
  useEffect(() => {
    const fetchData = async () => {
      setDropDowns(await GETCOLLECTION("DropDown"));
    };
    fetchData();
  }, []);
  const Render = dropDowns.map((DropDown) => {
    if (DropDown.id == "0") {
      return;
    }
    const TabsToRender = DropDown.Pages.reduce((acc, pageId) => {
      const page = TabsAR.find((item) => item.id === pageId);
      if (page && page.PageURL) {
        acc.push(page);
      }
      return acc;
    }, []);
    return (
      <li key={DropDown.id} className="dropdown" style={{ cursor: "pointer" }}>
        <Link
          style={{ textDecoration: "none" }}
          to={DropDown?.Link && `${DropDown.Link}`}
          onClick={() => {
            setActivePage(DropDown.Name);
          }}
          className={
            ActivePage === DropDown.Name ? "ActiveLink PageName" : "PageName"
          }
        >
          <span>
            {DropDown.Name} {TabsToRender.length > 0 && <img src={ArrowDown} />}
          </span>
        </Link>
        {TabsToRender.length > 0 && (
          <ul
            style={{ backgroundColor: getDataFromLocalStorage("NavBGColor") }}
          >
            {TabsToRender.map((link) => (
              <li key={link.id} className="LinkBox">
                {link.Icon && (
                  <div className="left">
                    <img src={link.Icon} />
                  </div>
                )}
                <div className="Right">
                  <Link
                    onClick={() => {
                      setActiveServicePage(link);
                      setActivePage(link.PageURL);
                    }}
                    className={
                      ActivePage === link.PageURL
                        ? "ActiveLink PageName"
                        : "PageName"
                    }
                    to={
                      DropDown.id === "1"
                        ? `${DropDown.Link}`
                        : `/${link.PageURL}`
                    }
                  >
                    {link.PageName}
                  </Link>
                  {link.Description && (
                    <span className="Description"> {link.Description}</span>
                  )}
                </div>
                <div className="right">
                  <img src={ArrowLeft} />
                </div>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  });

  return (
    <>
      <nav id="navbar" className="navbar ">
        <ul>
          <li>
            <Link
              className={ActivePage === "" ? "ActiveLink" : ""}
              onClick={() => {
                setActivePage("");
              }}
              style={{ textDecoration: "none" }}
              to="/"
            >
              Home
            </Link>
          </li>
          {Render}
          {RenderNonList}
          <li>
            <Link
              className={ActivePage === "Blog" ? "ActiveLink" : ""}
              onClick={() => {
                setActivePage("Blog");
              }}
              style={{ textDecoration: "none" }}
              to="Blog"
            >
              Blog
            </Link>
          </li>
        </ul>
      </nav>
      <Link
        style={{ textDecoration: "none" }}
        to={`/${TabsAR?.find((page) => page.id === "34")?.PageURL}`}
      >
        <button
          className={ActivePage === "Contact Us" ? "Empty Button" : "Button"}
          style={{ color: ActivePage === "Contact Us" ? "black" : "white" }}
          onClick={() => {
            setActivePage("Contact");
          }}
        >
          Contact Us
        </button>
      </Link>
    </>
  );
};

export default NavDesktop;
