import React, { useState, useEffect } from "react";
import "./BlogHome.css";
import "swiper/css";
import ArticlePreview from "../ArticlePreview/ArticlePreview";
import RenderAllArticles from "../RenderAllArticles/RenderAllArticles";
import { GETCOLLECTION } from "../../../server";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";

const BlogHome = ({ users }) => {
  const [activePage, setActivePage] = useState("main");
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = React.useState("All");
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const FetchArticles = async () => {
      const tempArticles = await GETCOLLECTION("Articles");
      const uniqueCategories = new Set();
      tempArticles.forEach((Article) => {
        uniqueCategories.add(Article.category);
      });

      const updatedCategories = Array.from(uniqueCategories);
      setCategories(updatedCategories);
      setArticles(tempArticles);
    };
    FetchArticles();
  }, []);

  const filteredArticles =
    activeTab === "All"
      ? articles
      : articles.filter((Article) => Article.category === activeTab);

  const RenderRecent = filteredArticles?.map((Article, index) => {
    if (index < 6) {
      return (
        <ArticlePreview
          key={Article.id}
          Article={Article}
          delay={index * 0.2}
        />
      );
    } else {
      return null;
    }
  });

  const RenderRoles = categories.map((category) => {
    return (
      <li
        onClick={() => {
          setActiveTab(category);
        }}
        key={category}
        className={`TabItem ${category === activeTab ? "active" : ""}`}
      >
        {category}
      </li>
    );
  });

  const RenderSlider = categories.map((category) => {
    return (
      <SwiperSlide key={category}>
        <li
          onClick={() => {
            setActiveTab(category);
          }}
          className={`TabItem Slider ${category === activeTab ? "active" : ""}`}
        >
          {category}
        </li>
      </SwiperSlide>
    );
  });

  return (
    <div className="BlogPage">
      <div className="BlogMain">
        <ul className="Roles">
          <li
            className={`TabItem ${"All" === activeTab ? "active" : ""}`}
            onClick={() => {
              setActiveTab("All");
            }}
          >
            All
          </li>
          {categories.length < 4 ? (
            RenderRoles
          ) : (
            <Swiper
              navigation={true}
              slidesPerView={3}
              spaceBetween={10}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                900: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                250: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              className="Category-swiper"
            >
              {RenderSlider}
            </Swiper>
          )}
        </ul>
        {activePage === "main" && (
          <>
            <p
              className="ViewArticles"
              onClick={() => {
                setActivePage("AllArticles");
              }}
            >
              View All Articles
            </p>

            <div className="Recent-Blogs">
              {RenderRecent.length === 0 && (
                <h4 style={{ textAlign: "center" }}>No Articles yet</h4>
              )}
              {RenderRecent}
            </div>
          </>
        )}
        {activePage === "AllArticles" && (
          <RenderAllArticles
            setActivePage={setActivePage}
            Articles={articles}
            Users={users}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            categories={categories}
          />
        )}
      </div>
    </div>
  );
};

export default BlogHome;
