import React from "react";
import "./WebSettings.css";
import HeaderSettings from "./MainPageSettings/HeaderSettings";
import { SETDOC, GETCOLLECTION } from "../../../server";
import { CreateToast } from "../../../App";
import Section1 from "./MainPageSettings/Section1";
import Section3 from "./MainPageSettings/Section3";
import Section4 from "./MainPageSettings/Section4";
import Section6 from "./MainPageSettings/Section6";
import Section7 from "./MainPageSettings/Section7";
import Section2 from "./MainPageSettings/Section2";
import FooterEdit from "./MainPageSettings/Footer";

const Main = ({
  Data,
  setActivePage,
  ActivePage,
  activeTab,
  setFetchedData,
  setSaving,
  Tabs,
  setEdited,
  edited,
}) => {
  const keysArray = Object.keys(Data);
  const sortedArray = keysArray.sort((a, b) => {
    const numberA = parseInt(a.match(/\d+$/)); // Extract number from the end of 'a'
    const numberB = parseInt(b.match(/\d+$/)); // Extract number from the end of 'b'

    if (isNaN(numberA)) {
      return 1; // 'a' does not have a number, move it to the back
    } else if (isNaN(numberB)) {
      return -1; // 'b' does not have a number, move it to the back
    } else {
      return numberA - numberB; // Compare the extracted numbers
    }
  });
  const RenderNav = sortedArray.map((tab, index) => {
    if (tab === "Section5" || tab === "newPageOrder" || tab === "PageOrder") {
      return null; // Skip this element
    }

    const delayString = (index * 0.1).toString() + "s";
    let nameToRender;
    let Description;

    switch (tab) {
      case "Section8":
        Description = Data[tab].Description;
        nameToRender = "Section2";
        break;

      default:
        Description = Data[tab].Description;
        nameToRender = tab;
        break;
    }

    return (
      <li
        className=" animate__fadeIn"
        style={{
          animationDelay: delayString,
          display: "flex",
          flexDirection: "column",
        }}
        key={tab}
        onClick={() => {
          setActivePage(tab);
        }}
      >
        {nameToRender}
        {Description && <p>Description:{Description}</p>}
      </li>
    );
  });
  const UpdateData = async (ChangedValue, NewValue) => {
    setSaving(true);
    CreateToast("Updating Data..", "info", 1000);
    const DataToSend = { ...Data, [ChangedValue]: NewValue };
    await SETDOC("customization", "Main", { ...DataToSend }, false);
    setFetchedData(await GETCOLLECTION("customization"));
    CreateToast("Data Updated", "success", 2000);
    setSaving(false);
  };
  return (
    <div>
      {activeTab === "Landing Page" && ActivePage === "" && (
        <ul className="NavWrapper">
          <>{RenderNav}</>
        </ul>
      )}
      {ActivePage === "Header" && (
        <HeaderSettings
          Data={Data.Header}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}
      {ActivePage === "Section1" && (
        <Section1
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section1}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}
      {ActivePage === "Section8" && (
        <Section2
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section8}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}
      {ActivePage === "Section3" && (
        <Section3
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section3}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}
      {ActivePage === "Section4" && (
        <Section4
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section4}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}

      {ActivePage === "Section6" && (
        <Section6
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section6}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}
      {ActivePage === "Section7" && (
        <Section7
          PageOrder={Data.PageOrder}
          FetchedData={Data.Section7}
          UpdateData={UpdateData}
          setEdited={setEdited}
          edited={edited}
        />
      )}

      {ActivePage === "FooterData" && (
        <FooterEdit
          FetchedData={Data.FooterData}
          UpdateData={UpdateData}
          Tabs={Tabs}
          setEdited={setEdited}
          edited={edited}
        />
      )}
    </div>
  );
};

export default Main;
