import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import MyModal from "../../../PopUps/Confirm/Confirm";
import { CreateToast } from "../../../../App";
import sortBy from "sort-by";
import Upload from "../../../../assets/upload.png";
import { DELETEPHOTO, UPLOADPHOTO } from "../../../../server";
import Input from "../../../Input/Input";
import SimpleEditor from "./SimpleEditor";

const Section7 = ({
  FetchedData,
  UpdateData,
  setEdited,
  edited,
  PageOrder,
}) => {
  const [data, setData] = useState(FetchedData);
  const [photoUploaded, setPhotoUploaded] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDescription, setShowDescription] = useState(null);
  const [NewCard, setNewCard] = useState({
    URL: "",
    ID: "",
    Name: "",
    Link: "",
  });
  const [pageOrder, setPageOrder] = useState(PageOrder);

  const handleSelectChange = (key, value) => {
    const oldValue = pageOrder[key];
    const sectionToSwap = Object.keys(pageOrder).find(
      (key) => pageOrder[key] === value
    );
    setPageOrder((prev) => ({
      ...prev,
      [key]: value,
      [sectionToSwap]: oldValue,
    }));
  };
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handlePrimaryAction = () => {
    setEdited(true);

    if (!photoUploaded) {
      CreateToast("photo uploading please wait", "error");
      return;
    }
    handleCloseModal();
    setData((prev) => {
      return { ...prev, Slider: [...prev.Slider, NewCard] };
    });

    setNewCard({ URL: "", ID: "", Name: "" });
    setPhotoUploaded(false);
  };
  const handleMainInput = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "url") {
      CreateToast("uploading photo", "info", 2000);
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/section7/${NewCard.ID}.png`,
        Photo
      );
      setNewCard((prev) => {
        return {
          ...prev,
          URL: url,
        };
      });
      CreateToast("photo uploaded", "success");
      setPhotoUploaded(true);
      return;
    }
    setNewCard((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const DeleteCard = (id) => {
    const NewCards = data.Slider.filter((Card) => {
      return Card.ID !== id;
    });
    DELETEPHOTO(`/customization/section7/${id}.png`);
    setData((prev) => {
      return { ...prev, Slider: NewCards };
    });
  };
  const changePhoto = async (e, id) => {
    CreateToast("uploading photo", "info", 2000);
    const Photo = e.target.files[0];
    const url = await UPLOADPHOTO(`/customization/section7/${id}.png`, Photo);
    const fileName = Photo.name;
    const fileExtension = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    const newData = data.Slider.map((card) => {
      if (card.ID === id) {
        return { ...card, URL: url, extension: fileExtension }; // Create a new object with updated URL
      } else {
        return card;
      }
    });

    setData((prev) => {
      return { ...prev, Slider: newData };
    });
    CreateToast("photo uploaded", "success");
    setPhotoUploaded(true);
  };
  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Short Description",
      selector: (row) => row.shortDescription,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Link",
      selector: (row) => row.Link,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "image",
      selector: (row) => row.url,
      sortable: true,
      center: true,
    },

    {
      name: "Options",
      selector: (row) => row.Options,
      sortable: true,
      center: true,
      width: "300px",
    },
  ];

  const TableData = data.Slider.map((Card) => {
    const handleChange = (e) => {
      const { name, value } = e.target;
      let oldData = data.Slider;
      let newData = oldData.map((oldCard) => {
        if (oldCard.ID === Card.ID) {
          return {
            ...oldCard,
            [name]: value,
          };
        } else {
          return oldCard;
        }
      });
      setData((prev) => ({ ...prev, Slider: newData }));
    };
    return {
      id: Card.ID,
      Name: (
        <Input
          type="text"
          id="Name"
          name="Name"
          value={Card.Name}
          onChangeFunction={handleChange}
        />
      ),
      shortDescription: (
        <Input
          type="text"
          id="shortDescription"
          name="shortDescription"
          value={Card.shortDescription}
          onChangeFunction={handleChange}
        />
      ),
      Description: (
        <button
          className="Button"
          onClick={() => {
            setShowDescription(Card);
          }}
        >
          Description
        </button>
      ),
      url: <img src={Card.URL} style={{ maxWidth: "75px", margin: "20px" }} />,
      Link: (
        <Input
          type="url"
          id="Link"
          name="Link"
          value={Card.Link}
          onChangeFunction={handleChange}
        />
      ),
      Options: (
        <div className="Button-wrapper">
          <button
            className="Button Danger"
            onClick={() => {
              DeleteCard(Card.ID);
            }}
          >
            Delete
          </button>
          <div className="FormItem" id="logo">
            <label htmlFor={`ChangePhoto${Card.ID}`}>Change Photo</label>
            <input
              type="file"
              hidden
              required
              id={`ChangePhoto${Card.ID}`}
              name="url"
              onChange={(e) => {
                changePhoto(e, Card.ID);
              }}
            />
          </div>
        </div>
      ),
    };
  });

  useEffect(() => {
    let ID;
    data.Slider.sort(sortBy("ID"));
    if (data.Slider.length === 0) {
      ID = 1;
    } else {
      data.Slider.forEach((card) => {
        ID = +card.ID + 1;
      });
    }
    setNewCard((prev) => {
      return { ...prev, ID };
    });
  }, [data]);
  const handleCheckboxChange = () => {
    setData((prev) => ({ ...prev, Show: !prev.Show }));
  };
  const updateInput = (value) => {
    setShowDescription((prev) => {
      return {
        ...prev,
        Description: value,
      };
    });
  };
  const SaveDescription = () => {
    const sliderData = data.Slider;
    const targetProduct = sliderData.find(
      (Product) => Product.ID === showDescription.ID
    );
    targetProduct.Description = showDescription.Description;
    setData((prev) => ({ ...prev, Slider: sliderData }));
    setEdited(false);
    UpdateData("Section7", data);
    setShowDescription(null);
  };
  return (
    <div className="DataEntry Section1">
      {showModal && (
        <MyModal
          className="Confirm"
          show={showModal}
          handleClose={handleCloseModal}
          title="Add Card"
          primaryButtonText={`Add`}
          handlePrimaryAction={handlePrimaryAction}
        >
          <>
            <div className="FormItem" id="logo">
              <span>Logo:</span>
              <label htmlFor="thumbnailInput">
                <img
                  src={Upload}
                  style={{ width: "25px", cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                hidden
                required
                id="thumbnailInput"
                name="url"
                onChange={handleInput}
              />
            </div>

            <Input
              label="Name (optional)"
              type="text"
              required={true}
              id="Name"
              name="Name"
              value={NewCard.Name}
              onChangeFunction={handleInput}
            />

            <Input
              label="Link (optional)"
              type="url"
              id="Link"
              name="Link"
              value={NewCard.Link}
              onChangeFunction={handleInput}
            />
          </>
        </MyModal>
      )}
      {showDescription && (
        <div className={showDescription ? "popup open" : "popup"}>
          <div className="EditorWrapper">
            <SimpleEditor
              handlePostBodyChange={updateInput}
              toolBarID={"ToolBar50"}
              oldValue={showDescription?.Description}
            />
            <button className="Button View" onClick={SaveDescription}>
              Save
            </button>
          </div>
        </div>
      )}
      <div className="boxWrapper">
        <h4 className="BoxTitle">General</h4>

        <div className="formItem form-check CheckBox">
          <label className="form-check-label">
            Show Section:
            <input
              className="form-check-input"
              type="checkbox"
              checked={data.Show}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <label>
          Section background Color:
          <input
            type="color"
            className="ColorPicker"
            value={data.BGColor}
            name="BGColor"
            onChange={handleMainInput}
          />
        </label>
        <div className="select-container">
          <span>Section Place</span>
          <select
            className="styled-select"
            value={pageOrder.Section7}
            onChange={(e) =>
              handleSelectChange("Section7", parseInt(e.target.value))
            }
          >
            <option value={1}>{1}</option>
            <option value={2}>{2}</option>
            <option value={3}>{3}</option>
            <option value={4}>{4}</option>
            <option value={5}>{5}</option>
            <option value={6}>{6}</option>
          </select>
        </div>
        <Input
          label="Section Description that appears in dashboard"
          id="Description"
          name="Description"
          value={data.Description}
          onChangeFunction={handleMainInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Content</h4>
        <Input
          label="Title"
          type="text"
          required={true}
          id="Title"
          name="Title"
          value={data.Title}
          onChangeFunction={handleMainInput}
          customWidth="70%"
        />
        <Input
          label="Section Tag"
          id="Tag"
          name="Tag"
          value={data.Tag}
          onChangeFunction={handleMainInput}
          customWidth="70%"
        />{" "}
        <Input
          label="Paragraph"
          type="text"
          required={true}
          id="paragraph"
          name="paragraph"
          value={data.paragraph}
          onChangeFunction={handleMainInput}
          textarea={true}
          customWidth="70%"
        />
      </div>

      <div className="boxWrapper">
        <h4 className="BoxTitle">Cards</h4>

        <button
          className="Button Add"
          style={{ margin: "0px auto" }}
          onClick={handleShowModal}
        >
          Add Card
        </button>
        <DataTable
          className="Table  animate__fadeIn"
          style={{ animationDelay: ".4s" }}
          theme="light"
          highlightOnHover
          columns={columns}
          data={TableData}
        />
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            setEdited(false);
            UpdateData("PageOrder", pageOrder);
            UpdateData("Section7", data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Section7;
