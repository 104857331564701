import React from "react";
import "../Templates.css";
import "./Template11.css";
import Template8 from "../Template8/Template8";
import Section3 from "../../LandingPage/Section3/Section3";
import { Link } from "react-router-dom";
import AboutUsCards from "../../Cards/AboutUsCards/AboutUsCards";

const Template11 = ({ Data, Teams, LandingPageData }) => {
  const RenderData = Data.Cards.map((Card, index) => {
    return <AboutUsCards Data={Card} key={index} Order={index} />;
  });
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <div className="About">
      <section className="First">
        <div className="Left ">
          <div
            className="image-wrapper PhotoEffect"
            style={{ borderRadius: "26px", width: "489px" }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
              src={Data.firstSectionImage}
            />
          </div>
        </div>

        <div className="Right">
          {Data.Tag && (
            <div
              className="Tag"
              style={{
                backgroundColor:
                  getDataFromLocalStorage("TagBG") &&
                  getDataFromLocalStorage("TagBG"),
              }}
            >
              <span>{Data.Tag}</span>
            </div>
          )}
          {Data.Section1Header && <h1>{Data.Section1Header}</h1>}
          {Data.Section1Text && (
            <p data-aos="fade-left" className="sec1Para">
              {Data.Section1Text}
            </p>
          )}
          {Data.Button1Text && (
            <Link
              to={Data.Button1Link}
              data-aos="fade-left"
              data-aos-anchor=".sec1Para"
            >
              <div className="Button Empty">
                <span>{Data.Button1Text}</span>
              </div>
            </Link>
          )}
        </div>
      </section>
      <section className="Second">
        <div className="Left ">
          {Data.Section2Header && <h1>{Data.Section2Header}</h1>}
          {Data.Section2SubHeader && (
            <h3 data-aos="fade-right">{Data.Section2SubHeader}</h3>
          )}
          {Data.Section2Text && (
            <p data-aos="fade-right">{Data.Section2Text}</p>
          )}
        </div>
        <div
          className="Right"
          style={{
            gridTemplateColumns:
              Data.secondSectionThirdImage && Data.secondSectionSecondImage
                ? "50% 50%"
                : "",
          }}
        >
          {Data.secondSectionThirdImage && Data.secondSectionSecondImage && (
            <div className="firstColumn ">
              {Data.secondSectionThirdImage && (
                <div className="grid-item PhotoEffect">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={Data.secondSectionThirdImage}
                    alt="Image 3"
                    className="grid-image"
                  />
                </div>
              )}
              {Data.secondSectionSecondImage && (
                <div className="grid-item PhotoEffect">
                  <img
                    src={Data.secondSectionSecondImage}
                    alt="Image 2"
                    className="grid-image"
                  />
                </div>
              )}
            </div>
          )}
          <div className="secondColumn">
            <div className="grid-item PhotoEffect">
              <img
                src={Data.secondSectionFirstImage}
                alt="Image 1"
                className="grid-image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="Third">
        {Data.Section3Header && <h1>{Data.Section3Header}</h1>}

        <div className="cards-wrapper">{RenderData}</div>
      </section>
      <section className="Fourth">
        {Data.Section4Header && <h1>{Data.Section4Header}</h1>}
        <Template8 Data={Data} Teams={Teams} />
      </section>
      <Section3 ServerData={LandingPageData[0].Section3} />
    </div>
  );
};

export default Template11;
