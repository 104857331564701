import React, { useState, useEffect, useRef } from "react";

import Input from "../../../Input/Input";
import { CreateToast } from "../../../../App";
import { UPLOADPHOTO } from "../../../../server";
import Upload from "../../../../assets/upload.png";

const Template14 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "Phone-Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Phone-Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, PhoneIcon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else if (name === "Location-Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Location-Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, LocationIcon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else if (name === "Email-Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Email-Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, EmailIcon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  return (
    <div className="dataEntry Hosting">
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="formItem">
          <span>Phone Icon:</span>
          <label htmlFor="Phone-Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Phone-Icon"
            name="Phone-Icon"
            onChange={handleInput}
          />
        </div>
        <div className="formItem">
          <span>Location Icon:</span>
          <label htmlFor="Location-Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Location-Icon"
            name="Location-Icon"
            onChange={handleInput}
          />
        </div>
        <div className="formItem">
          <span>Email Icon:</span>
          <label htmlFor="Email-Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Email-Icon"
            name="Email-Icon"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          label="Header Title"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Bottom Title"
            type="text"
            id="BottomTitle"
            name="BottomTitle"
            required={true}
            value={data.BottomTitle}
            onChangeFunction={handleInput}
          />
        </div>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Page Header Title"
            type="text"
            id="PageTitle"
            name="PageTitle"
            required={true}
            value={data.PageTitle}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Form Title"
            type="text"
            id="formTitle"
            name="formTitle"
            value={data.formTitle}
            onChangeFunction={handleInput}
          />
        </div>
      </div>

      <div className="boxWrapper">
        <h4 className="BoxTitle">Contact Info</h4>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Email"
            type="email"
            id="Email"
            name="Email"
            onChangeFunction={handleInput}
            value={data.Email}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Phone"
            type="text"
            id="Phone"
            name="Phone"
            onChangeFunction={handleInput}
            value={data.Phone}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">First Address Info</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Text"
            type="text"
            id="firstAddressText"
            name="firstAddressText"
            value={data.firstAddressText}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address"
            type="text"
            id="firstAddress"
            name="firstAddress"
            value={data.firstAddress}
            onChangeFunction={handleInput}
          />
        </div>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Latitude "
            type="number"
            id="firstAddressLatitude"
            name="firstAddressLatitude"
            value={data.firstAddressLatitude}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Longitude "
            type="number"
            id="firstAddressLongitude"
            name="firstAddressLongitude"
            value={data.firstAddressLongitude}
            onChangeFunction={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Second Address Info</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Text"
            type="text"
            id="secondAddressText"
            name="secondAddressText"
            value={data.secondAddressText}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address"
            type="text"
            id="secondAddress"
            name="secondAddress"
            value={data.secondAddress}
            onChangeFunction={handleInput}
          />
        </div>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Latitude "
            type="number"
            id="secondAddressLatitude"
            name="secondAddressLatitude"
            value={data.secondAddressLatitude}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Address Longitude "
            type="number"
            id="secondAddressLongitude"
            name="secondAddressLongitude"
            value={data.secondAddressLongitude}
            onChangeFunction={handleInput}
          />
        </div>
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template14;
