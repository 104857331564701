import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
const HeaderMainPage = (props) => {
  const severData = props.ServerData;
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <div
      className="Header MainPage"
      style={{ backgroundColor: severData.BGColor }}
    >
      <div className="Text">
        {severData.Tag && (
          <div
            className="Tag"
            style={{
              backgroundColor:
                getDataFromLocalStorage("TagBG") &&
                getDataFromLocalStorage("TagBG"),
            }}
          >
            <span>{severData.Tag}</span>
          </div>
        )}
        <h1
          id="HeaderTitle "
          style={{
            marginTop: props.screenWidth < 600 ? 0 : "unset",
          }}
        >
          {severData.Title}
        </h1>
        {severData.SubTitle && (
          <h3 className="animate__animated  animate__fadeInLeft">
            {severData.SubTitle}
          </h3>
        )}
        <div className="Buttons   animate__animated   animate__fadeInUp">
          {severData.ShowButton && (
            <Link
              style={{ textDecoration: "none" }}
              to={severData.firstButtonLink}
            >
              <button className="Button">{severData.buttonText}</button>
            </Link>
          )}
          {severData.ShowContact && (
            <div className="Button Empty">
              <Link
                style={{ textDecoration: "none" }}
                to={severData.secondButtonLink}
              >
                <span style={{ color: severData.ContactColor }}>
                  {severData.ContactText}
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
      {props.screenWidth > 1000 && (
        <div className="Right">
          <div className="First Col" style={{ animationDelay: "0s" }}>
            <div className=" photo-wrapper PhotoEffect">
              <img src={severData.thumbnail} />
            </div>
          </div>
          <div className="Second Col" style={{ animationDelay: ".2s" }}>
            {severData.secondImage && (
              <div className=" photo-wrapper PhotoEffect">
                <img src={severData.secondImage} />
              </div>
            )}
            {severData.thirdImage && (
              <div
                className=" photo-wrapper PhotoEffect  "
                style={{ animationDelay: ".5s" }}
              >
                <img src={severData.thirdImage} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMainPage;
