import React, { useState } from "react";
import "./ViewJob.css";
import arrowUp from "../../../assets/arrowUp.png";
import arrowLeft from "../../../assets/left-arrow.png";
import { useRef } from "react";
import { Link } from "react-router-dom";

const ViewJob = (props) => {
  const job = props.job;
  const jobContainerRef = useRef(null);

  const [jobExpanded, setJobExpanded] = useState(false);
  const expandJob = () => {
    if (jobContainerRef.current) {
      jobContainerRef.current.scrollTop = 0;
    }
    setJobExpanded((prev) => !prev);
  };
  return (
    <div
      className={`JobOffer ${jobExpanded ? "Active" : ""}`}
      ref={jobContainerRef}
    >
      <div className="JobHeader">
        <img
          src={arrowUp}
          onClick={expandJob}
          className={`Arrow ${jobExpanded ? "Active" : ""}`}
        />
        <h3>{job.Designation}</h3>

        {job.Experience && (
          <div className="Detail Exp">
            <span>Experience: </span>
            <span>{job.Experience}</span>
          </div>
        )}
      </div>

      <div className="JobDetails">
        {job.Designation && (
          <div className="Detail">
            <span>Designation: </span>
            <span>{job.Designation}</span>
          </div>
        )}
        {job.Qualification && (
          <div className="Detail">
            <span>Qualification: </span>
            <span> {job.Qualification}</span>
          </div>
        )}
        {job.Vacancy && (
          <div className="Detail">
            <span>Vacancy: </span>
            <span> {job.Vacancy}</span>
          </div>
        )}
        {job.JobLocation && (
          <div className="Detail">
            <span>Job Location: </span>
            <span>{job.JobLocation}</span>
          </div>
        )}
        {job.DateAdded && (
          <div className="Detail">
            <span>Date Added: </span>
            <span>{job.DateAdded}</span>
          </div>
        )}
      </div>

      {job.DescriptionContent && (
        <div className="Description">
          <p className="Description">Description</p>
          <div
            dangerouslySetInnerHTML={{ __html: job.DescriptionContent }}
          ></div>
        </div>
      )}
      {job.DesiredSkillsContent && (
        <div className="Description">
          <p className="Description">Desired Skills</p>
          <div
            dangerouslySetInnerHTML={{ __html: job.DesiredSkillsContent }}
          ></div>
        </div>
      )}
      <Link
        to={`/Application/${job.Designation}`}
        style={{ textDecoration: "none" }}
      >
        <button className="Button">
          Apply
          <img src={arrowLeft} style={{ width: "24px", height: "24px" }} />
        </button>
      </Link>
    </div>
  );
};

export default ViewJob;
