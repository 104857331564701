import React, { useState } from "react";
import { CreateToast } from "../../../App"; // Assuming this is a notification utility
import {
  BackUpDate,
  EMPTYFOLDER,
  GETCOLLECTIONFORBACKUP,
  UPDATECOLLECTION,
} from "../../../server"; // Import the necessary functions
import { UPLOADPHOTO } from "../../../server"; // Import the upload function
import Upload from "../../../assets/upload.png";
const Backup = () => {
  const [isBackingUp, setIsBackingUp] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  const backupFirebase = async () => {
    setIsBackingUp(true);
    try {
      const collectionNames = [
        "Articles",
        "Blog",
        "DropDown",
        "Projects",
        "Team",
        "customization",
        "categories",
      ];
      for (let name of collectionNames) {
        await backupCollection(name); // Ensure backup is complete before proceeding
      }

      CreateToast("Backup successfully created", "success");
    } catch (error) {
      console.error("Error creating backup:", error);
      CreateToast("Error creating backup", "error");
    } finally {
      setIsBackingUp(false); // Reset loading state
    }
  };

  const backupAndUpload = async () => {
    setIsUploading(true);
    try {
      const collectionNames = [
        "Articles",
        "Blog",
        "DropDown",
        "Projects",
        "Team",
        "customization",
        "categories",
      ];
      await EMPTYFOLDER("/backups");
      for (let name of collectionNames) {
        const backupData = await createBackupData(name);
        const currentDate = formatDate();
        const fileName = `${name}-backup-${currentDate}.json`;
        await uploadBackup(fileName, backupData);
      }
      CreateToast(`Backup uploaded successfully`, "success");
      await BackUpDate();
    } catch (error) {
      console.error("Error creating or uploading backup:", error);
      CreateToast("Error creating or uploading backup", "error");
    } finally {
      setIsUploading(false); // Reset loading state
    }
  };

  const createBackupData = async (name) => {
    try {
      const collectionData = await GETCOLLECTIONFORBACKUP(name);

      if (!collectionData || !Array.isArray(collectionData)) {
        throw new Error(`Expected an array but got ${typeof collectionData}`);
      }

      const allCollections = collectionData.map((doc) => ({
        id: doc.id,
        data: doc,
      }));

      return JSON.stringify(allCollections, null, 2);
    } catch (error) {
      console.error(`Error creating backup data for ${name}:`, error);
      throw error;
    }
  };

  const uploadBackup = async (fileName, data) => {
    const blob = new Blob([data], { type: "application/json" });
    const path = `backups/${fileName}`; // Define the path in Firebase Storage
    const url = await UPLOADPHOTO(path, blob);
    return url;
  };

  const backupCollection = async (name) => {
    try {
      const collectionData = await GETCOLLECTIONFORBACKUP(name);

      if (!collectionData || !Array.isArray(collectionData)) {
        throw new Error(`Expected an array but got ${typeof collectionData}`);
      }

      const allCollections = collectionData.map((doc) => ({
        id: doc.id,
        data: doc,
      }));

      const jsonData = JSON.stringify(allCollections, null, 2);
      const blob = new Blob([jsonData], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      const currentDate = formatDate();

      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}-backup-${currentDate}.json`;
      a.click();

      URL.revokeObjectURL(url);
      console.log(`Backup for ${name} completed successfully.`);
    } catch (error) {
      console.error(`Error creating backup for ${name}:`, error);
    }
  };

  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFileUpload = async (event) => {
    CreateToast("Restoring data..");
    const files = event.target.files;
    if (files.length === 0) return;
    setIsRestoring(true);

    try {
      for (const file of files) {
        const fileName = file.name;
        const collectionName = fileName.split("-")[0]; // Extract the collection name
        const fileContent = await file.text(); // Read file content
        const jsonData = JSON.parse(fileContent); // Parse JSON data

        if (Array.isArray(jsonData)) {
          const flattenedData = jsonData.map((item) => ({
            id: item.data.id,
            ...item.data.data,
          }));

          await UPDATECOLLECTION(collectionName, flattenedData); // Update Firestore collection
          console.log(`Updated collection ${collectionName} with ${fileName}`);
        } else {
          console.error(`Invalid data format in file: ${fileName}`);
        }
      }
      CreateToast("Backup data restored successfully", "success");
    } catch (error) {
      console.error("Error uploading files:", error);
      CreateToast("Error uploading files", "error");
    }
    setIsRestoring(false);
  };

  return (
    <div className="DataEntry">
      <div className="boxWrapper">
        <h4 className="BoxTitle">Backup Data</h4>
        <button
          onClick={backupFirebase}
          disabled={isBackingUp}
          className="Button"
        >
          {isBackingUp
            ? "Backing up..."
            : "Create Backup and download JSON files"}
        </button>
        <button
          onClick={backupAndUpload}
          disabled={isUploading}
          className="Button"
        >
          {isUploading
            ? "Uploading backup..."
            : "Create Backup and upload to Database"}
        </button>
        <p style={{ textAlign: "center" }}>
          Backups are done automatically weekly
        </p>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Restore Data</h4>
        <p style={{ textAlign: "center" }}>
          if you have json file you can restore the data directly
        </p>
        <div className="FormItem" id="Thumbnail">
          <span>Upload JSON files: </span>
          <label htmlFor="FilesUpload">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>
          <input
            multiple="true"
            type="file"
            id="FilesUpload"
            accept=".json"
            onChange={handleFileUpload}
            disabled={isRestoring}
            className="FileInput"
            hidden
          />
        </div>

        <p style={{ textAlign: "center" }}>
          but if you dont contact us at harptec@info.com to restore your data
        </p>
      </div>
    </div>
  );
};

export default Backup;
