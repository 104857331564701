import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "621px",
};

const MapComponent = ({ locations }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBFAht1G9sVO3g1nOmHI9oPe-BmGGvdovc">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={locations[0]}
        zoom={10}
      >
        {locations.map((location) => {
          return <Marker key={location} position={location} />;
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
