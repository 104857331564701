/* eslint-disable no-debugger */
import React, { useState, useEffect, useRef } from "react";
import Upload from "../../../../assets/upload.png";
import { CreateToast } from "../../../../App";
import { UPLOADPHOTO } from "../../../../server";
import "../DataEntry.css";
import Input from "../../../Input/Input";
const HeaderSettings = ({ Data, UpdateData, setEdited }) => {
  const [data, setData] = useState(Data);

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setData((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  const handleInput = async (e) => {
    const { name, value } = e.target;

    if (name === "Thumbnail") {
      CreateToast("uploading thumbnail", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(`/customization/Main/HeaderBG.jpg`, Photo);
      setData((prev) => {
        return {
          ...prev,
          thumbnail: url,
        };
      });
      CreateToast("thumbnail uploaded", "success");
      return;
    } else if (name === "secondImage") {
      CreateToast("uploading Second Image", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/Main/secondImage.jpg`,
        Photo
      );
      setData((prev) => {
        return {
          ...prev,
          secondImage: url,
        };
      });
      CreateToast("Second Image uploaded", "success");
      return;
    } else if (name === "thirdImage") {
      CreateToast("uploading Third Image", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/Main/thirdImage.jpg`,
        Photo
      );
      setData((prev) => {
        return {
          ...prev,
          thirdImage: url,
        };
      });
      CreateToast("Third Image uploaded", "success");
      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);

  return (
    <div className="DataEntry Head">
      <div className="boxWrapper">
        <h4 className="BoxTitle">Content</h4>
        <label>
          background Color:
          <input
            type="color"
            name="BGColor"
            className="ColorPicker"
            value={Data.BGColor}
            onChange={handleInput}
          />
        </label>
        <Input
          label="Title"
          type="text"
          required={true}
          id="Title"
          name="Title"
          value={data.Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          textarea={true}
          label="Sub Title"
          required={true}
          id="SubTitle"
          name="SubTitle"
          value={data.SubTitle}
          customWidth="70%"
          onChangeFunction={handleInput}
        />

        <Input
          label="Header Tag"
          id="Tag"
          name="Tag"
          value={data.Tag}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">First Button</h4>

        <div className="CheckMarkWrapper" style={{ width: "70%" }}>
          <Input
            label="Text"
            required={true}
            id="buttonText"
            name="buttonText"
            value={data.buttonText}
            onChangeFunction={handleInput}
          />
          <div className="CheckBox">
            <span>Show</span>
            <input
              className="form-check-input"
              type="checkbox"
              checked={data.ShowButton}
              name="ShowButton"
              onChange={handleCheckboxChange}
            />
          </div>
        </div>

        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Link"
            required={true}
            id="firstButtonLink"
            name="firstButtonLink"
            value={data.firstButtonLink}
            onChangeFunction={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Second Button</h4>

        <div className="CheckMarkWrapper" style={{ width: "70%" }}>
          <Input
            label="Text"
            required={true}
            id="ContactText"
            name="ContactText"
            value={data.ContactText}
            onChangeFunction={handleInput}
          />
          <div className="CheckBox">
            <span>Show</span>

            <input
              className="form-check-input"
              type="checkbox"
              checked={data.ShowContact}
              name="ShowContact"
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Link"
            required={true}
            id="secondButtonLink"
            name="secondButtonLink"
            value={data.secondButtonLink}
            onChangeFunction={handleInput}
          />
        </div>
      </div>

      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="UploadWrapper">
          <div className="FormItem">
            <span>First image: </span>
            <label htmlFor="thumbnailInput">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="thumbnailInput"
              name="Thumbnail"
              onChange={handleInput}
            />
          </div>
          <div className="FormItem">
            <span>Second image: </span>
            <label htmlFor="secondImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="secondImage"
              name="secondImage"
              onChange={handleInput}
            />
          </div>
          <div className="FormItem">
            <span>Third image: </span>
            <label htmlFor="thirdImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="thirdImage"
              name="thirdImage"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
      <button
        className="Button View"
        id="Submit"
        onClick={() => {
          UpdateData("Header", data);
        }}
      >
        Save
      </button>
    </div>
  );
};

export default HeaderSettings;
