import React, { useState, useEffect, useRef } from "react";
import MyModal from "../../../PopUps/Confirm/Confirm";
import { CreateToast } from "../../../../App";
import sortBy from "sort-by";
import "./SidePages.css";
import PriceCard from "../../../Cards/PriceCard/PriceCard";
import Input from "../../../Input/Input";
import { UPLOADPHOTO } from "../../../../server";
import Upload from "../../../../assets/upload.png";

const Template6 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);

  const [proText, setProText] = useState("");
  const [highLightedID, setHighlightedId] = useState(null);
  const changeHighlight = (id) => {
    setHighlightedId(id);
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [pricingCard, setPricingCard] = useState({
    PerMonth: false,
    Price: "",
    description: "",
    title: "",
    pros: [],
    id: "",
  });
  const CheckData = () => {
    handleShowModal();
  };
  const handlePrimaryAction = () => {
    handleCloseModal();
    setData((prev) => {
      return { ...prev, Plans: [...prev.Plans, pricingCard] };
    });
    setPricingCard({
      PerMonth: false,
      Price: "",
      description: "",
      title: "",
      pros: [],
      id: "",
    });
  };
  const addPro = () => {
    if (proText === "") {
      return;
    }
    let id;
    if (pricingCard.pros.length === 0) {
      id = 0;
    } else {
      pricingCard.pros.sort(sortBy("id"));
      pricingCard.pros.forEach((category) => {
        id = +category.id + 1;
      });
    }
    setPricingCard((prev) => {
      return { ...prev, pros: [...prev.pros, { id: id, text: proText }] };
    });
    setProText("");
  };
  const DeletePro = (id) => {
    let tempList = pricingCard.pros.filter((pro) => {
      return pro.id != id;
    });
    setPricingCard((prev) => {
      return { ...prev, pros: tempList };
    });
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name === "Icon") {
      CreateToast("uploading Icon", "info", 2000);

      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(
        `/customization/SidePages/${Data.id}/Icon.jpg`,
        Photo
      );
      setData((prev) => {
        return { ...prev, Icon: url };
      });
      CreateToast("photo uploaded", "success", 2000);

      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const DeletePricing = (id) => {
    const NewCards = data.Plans.filter((Card) => {
      return Card.id !== id;
    });
    setData((prev) => {
      return { ...prev, Plans: NewCards };
    });
  };

  useEffect(() => {
    let PricingID;
    if (data.Plans.length === 0) {
      PricingID = 1;
    } else {
      data.Plans.sort(sortBy("id"));
      data.Plans.forEach((category) => {
        PricingID = +category.id + 1;
      });
    }
    setPricingCard((prev) => {
      return { ...prev, id: PricingID };
    });
  }, [data]);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  const renderDummyPricingData = data.Plans.map((PricePlan) => {
    return (
      <div
        key={PricePlan.id}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <PriceCard
          data={PricePlan}
          changeHighlight={changeHighlight}
          highLightedID={highLightedID}
        />
        <button
          className="Button Danger"
          onClick={() => {
            DeletePricing(PricePlan.id);
          }}
        >
          Delete
        </button>
      </div>
    );
  });

  return (
    <div className="DataEntry Hosting">
      {showModal && (
        <MyModal
          className="Confirm PricingModal"
          show={showModal}
          handleClose={handleCloseModal}
          title="add Pricing Card"
          primaryButtonText={`add`}
          handlePrimaryAction={() => {
            handlePrimaryAction("Pricing");
          }}
        >
          <>
            <div className="formItem">
              <label htmlFor="Subscription">Subscription:</label>
              <input
                id="Subscription"
                type="checkbox"
                name="PerMonth"
                style={{ height: "20px" }}
                checked={pricingCard.PerMonth}
                onChange={(e) =>
                  setPricingCard((prev) => {
                    return { ...prev, PerMonth: e.target.checked };
                  })
                }
              />
            </div>

            <Input
              label="Title:"
              type="text"
              id="title"
              name="title"
              value={pricingCard.title}
              onChangeFunction={(event) => {
                setPricingCard((prev) => {
                  return { ...prev, [event.target.name]: event.target.value };
                });
              }}
            />

            <Input
              label="Price:"
              type="text"
              id="Price"
              name="Price"
              value={pricingCard.Price}
              onChangeFunction={(event) => {
                setPricingCard((prev) => {
                  return { ...prev, [event.target.name]: event.target.value };
                });
              }}
            />
            <Input
              label="Description:"
              type="text"
              id="description"
              name="description"
              value={pricingCard.description}
              onChangeFunction={(event) => {
                setPricingCard((prev) => {
                  return { ...prev, [event.target.name]: event.target.value };
                });
              }}
              textarea={true}
            />

            <div className="formItem">
              <div className="Pros-Wrapper">
                <div className="Pros-Adder">
                  <Input
                    label="Pros"
                    type="text"
                    name="pro"
                    value={proText}
                    onChangeFunction={(e) => {
                      setProText(e.target.value);
                    }}
                  />

                  <button className="Button Add" onClick={addPro}>
                    Add
                  </button>
                </div>
                <ul>
                  {pricingCard.pros?.map((pro) => {
                    return (
                      <li key={pro.id}>
                        {pro.text}
                        <button
                          className="Button Danger"
                          onClick={() => {
                            DeletePro(pro.id);
                          }}
                        >
                          Delete
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </>
        </MyModal>
      )}
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>
        <div className="formItem">
          <span>Icon:</span>
          <label htmlFor="Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Icon"
            name="Icon"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL:"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Product Description: "
          type="text"
          id="Description"
          name="Description"
          required={true}
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Name in navigation :"
          type="text"
          id="PageName"
          name="PageName"
          value={data.PageName}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Header Title:"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Bottom Title:"
            type="text"
            required={true}
            id="BottomTitle"
            name="BottomTitle"
            value={data.BottomTitle}
            onChangeFunction={handleInput}
            style={{ color: data.BottomColor }}
          />
        </div>
        <Input
          textarea={true}
          label="Introduction"
          type="textarea"
          id="Para"
          name="Para"
          value={data.Para}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Pricing Cards</h4>
        <div>
          <button
            className="Button Add"
            style={{ margin: "0px auto" }}
            onClick={CheckData}
          >
            Add Pricing Card
          </button>
        </div>
        <div className="Pricing-container">{renderDummyPricingData}</div>
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template6;
