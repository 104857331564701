import React, { useRef } from "react";
import "./ProductCard.css";
const ProductCard = ({ skill, setActiveTab, activeTab }) => {
  return (
    <li
      className={`Tab ${activeTab?.ID === skill.ID ? "Active" : ""}  
        `}
      onClick={() => {
        setActiveTab(skill);
      }}
    >
      <h5>{skill.Name}</h5>
      <p>{skill.shortDescription}</p>
    </li>
  );
};

export default ProductCard;
