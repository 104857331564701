import React, { useState } from "react";
import ArticlePreview from "../ArticlePreview/ArticlePreview";
import Pagination from "./Pagination";

const RenderAllArticles = ({ Articles, setActivePage, activeTab }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ArticlesPerPage] = useState(8);

  // Filter articles based on the active tab (category)
  const filteredArticles =
    activeTab === "All"
      ? Articles // If "All" is selected, show all articles
      : Articles.filter((Article) => Article.category === activeTab);

  // Paginate the filtered articles
  const indexOfLastPost = currentPage * ArticlesPerPage;
  const indexOfFirstPost = indexOfLastPost - ArticlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const renderArticles = currentArticles.map((Article, index) => {
    return (
      <ArticlePreview key={Article.id} Article={Article} delay={index * 0.2} />
    );
  });

  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <>
      <p
        className="ViewArticles"
        onClick={() => {
          setActivePage("main");
        }}
      >
        View Recent Articles
      </p>
      <div className="Recent-Blogs" id="Recent">
        {renderArticles.length === 0 && (
          <h2 style={{ textAlign: "center" }}>No Articles yet</h2>
        )}
        {renderArticles}
      </div>

      <Pagination
        ArticlesPerPage={ArticlesPerPage}
        TotalArticles={filteredArticles.length}
        paginate={paginate}
      />
    </>
  );
};

export default RenderAllArticles;
