import React, { useState, useEffect, useRef } from "react";
import Upload from "../../../../assets/upload.png";
import Input from "../../../Input/Input";
import { CreateToast } from "../../../../App";
import { UPLOADPHOTO } from "../../../../server";
const Section6 = ({
  FetchedData,
  UpdateData,
  setEdited,
  edited,
  PageOrder,
}) => {
  const [data, setData] = useState(FetchedData);
  const [pageOrder, setPageOrder] = useState(PageOrder);

  const handleSelectChange = (key, value) => {
    const oldValue = pageOrder[key];
    const sectionToSwap = Object.keys(pageOrder).find(
      (key) => pageOrder[key] === value
    );
    setPageOrder((prev) => ({
      ...prev,
      [key]: value,
      [sectionToSwap]: oldValue,
    }));
  };
  const handleInput = async (e) => {
    const { name, value } = e.target;

    if (name === "Thumbnail") {
      CreateToast("uploading thumbnail", "info");
      const Photo = e.target.files[0];
      const url = await UPLOADPHOTO(`/customization/Section6/BG.jpg`, Photo);
      setData((prev) => {
        return {
          ...prev,
          thumbnail: url,
        };
      });
      CreateToast("thumbnail uploaded", "success");
      return;
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const handleCheckboxChange = () => {
    setData((prev) => ({ ...prev, Show: !prev.Show }));
  };
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  return (
    <div className="DataEntry section6">
      <div className="boxWrapper">
        <h4 className="BoxTitle">General</h4>

        <div className="formItem form-check CheckBox">
          <label className="form-check-label">
            Show Section:
            <input
              className="form-check-input"
              type="checkbox"
              checked={data.Show}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <label>
          Section background Color:
          <input
            type="color"
            className="ColorPicker"
            value={data.BGColor}
            name="BGColor"
            onChange={handleInput}
          />
        </label>
        <div className="select-container">
          <span>Section Place</span>
          <select
            className="styled-select"
            value={pageOrder.Section6}
            onChange={(e) =>
              handleSelectChange("Section6", parseInt(e.target.value))
            }
          >
            <option value={1}>{1}</option>
            <option value={2}>{2}</option>
            <option value={3}>{3}</option>
            <option value={4}>{4}</option>
            <option value={5}>{5}</option>
            <option value={6}>{6}</option>
          </select>
        </div>
        <Input
          label="Section Description that appears in dashboard"
          id="Description"
          name="Description"
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Content</h4>

        <Input
          label="Section Title"
          type="text"
          required={true}
          id="Title"
          name="Title"
          value={data.Title}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Section Tag"
          id="Tag"
          name="Tag"
          value={data.Tag}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="UploadWrapper">
          <div className="FormItem">
            <span>Section image: </span>
            <label htmlFor="thumbnailInput">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="thumbnailInput"
              name="Thumbnail"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button  View"
          id="Submit"
          onClick={() => {
            setEdited(false);

            UpdateData("PageOrder", pageOrder);
            UpdateData("Section6", data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Section6;
