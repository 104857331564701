/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./components/Nav/Nav.js";
import Footer from "./components/Footer/Footer";
import { GETCOLLECTION, compareVisitTimestamps } from "./server";
import NotFound from "./components/NotFound/NotFound.js";
import MainPage from "./components/MainPage";
import Aos from "aos";
import BlogHome from "./components/Blog/BlogHome/BlogHome";
import BlogCategory from "./components/Blog/SidePages/BlogCategory";
import BlogAuthor from "./components/Blog/SidePages/BlogAuthor";
import ArticlePage from "./components/Blog/ArticlePage/ArticlePage";
import Portal from "./components/Portal/Portal";
import Settings from "./components/Settings/Settings";
import ArticleBuilder from "./components/Blog/ArticleBuilder/ArticleBuilder";
import Dashboard from "./components/Dashboard/Dashboard";
import UserDetails from "./components/Dashboard/UserDetails/UserDetails";
import Redirect from "./components/Redirect";
import CookiePopup from "./components/PopUps/CookiePopup";
import ViewMember from "./components/ViewMember/ViewMember";
import EditMember from "./components/Dashboard/Memebers/EditMember";
import EditBlog from "./components/Dashboard/EditBlog/EditBlog.js";
import Loading from "./components/Loading/Loading.js";
import HeaderMainPage from "./components/Header/HeaderMainPage.js";
import BasicHeader from "./components/Header/BasicHeader.js";
import Template15 from "./components/Templates/Template15/Template15.js";

export const CreateToast = (text, type, duration = 4000) => {
  let value;
  switch (type) {
    case "success":
      value = toast.success;
      break;
    case "info":
      value = toast.info;
      break;
    case "warning":
      value = toast.warn;
      break;
    case "error":
      value = toast.error;
      break;
    default:
      value = toast;
      break;
  }
  return value(text, {
    position: "bottom-right",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
function App() {
  const [agreeCookies] = useState(localStorage.getItem("Cookies"));
  const [users, setUsers] = useState(null);
  const [FetchedData, setFetchedData] = useState(null);
  const [BlogData, setBlogData] = useState(null);
  const [activeServicePage, setActiveServicePage] = useState(null);
  const [Categories, setCategories] = useState(null);
  const [Teams, setTeams] = useState(null);
  const [webData, setWebData] = useState(null);
  const [Projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [PageOrder, setPageOrder] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  const saveDataToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  useEffect(() => {
    const fetchData = async () => {
      const shouldFetchData = await compareVisitTimestamps();
      const isLocalStorageEmpty = !localStorage.getItem("customizationData");
      setUsers(await GETCOLLECTION("users"));

      if (shouldFetchData || isLocalStorageEmpty) {
        await Promise.all([
          GETCOLLECTION("customization"),
          GETCOLLECTION("Projects"),
          GETCOLLECTION("Blog"),
          GETCOLLECTION("categories"),
          GETCOLLECTION("Team"),
        ])
          .then(
            ([
              customizationData,
              projectsData,
              blogData,
              categoriesData,
              Teams,
            ]) => {
              setWebData(customizationData[2]);
              setPageOrder(customizationData[0].newPageOrder);
              setFetchedData(customizationData);
              setLoading(false);
              setProjects(projectsData);
              setTeams(Teams);
              setBlogData(blogData);
              setCategories(categoriesData);
              saveDataToLocalStorage("customizationData", customizationData);
              saveDataToLocalStorage("projectsData", projectsData);
              saveDataToLocalStorage("blogData", blogData);
              saveDataToLocalStorage("categoriesData", categoriesData);
              saveDataToLocalStorage("teams", Teams);
              saveDataToLocalStorage(
                "NavTheme",
                customizationData[2].NavTheme
                  ? customizationData[2].NavTheme
                  : "First"
              );
              saveDataToLocalStorage(
                "sidePagesHeaderColor",
                customizationData[2].Colors.sidePagesHeaderColor
              );
              saveDataToLocalStorage(
                "gradientColor1",
                customizationData[2].Colors.gradientColor1
              );
              saveDataToLocalStorage(
                "gradientColor2",
                customizationData[2].Colors.gradientColor2
              );
              saveDataToLocalStorage("TagBG", customizationData[2].TagBG);
              saveDataToLocalStorage(
                "NavBGColor",
                customizationData[2].Colors.NavBGColor
              );
            }
          )

          .catch((error) => {
            // Handle error
            console.log("Error fetching data", error);
          });
      } else {
        const customizationData = getDataFromLocalStorage("customizationData");
        const projectsData = getDataFromLocalStorage("projectsData");
        const blogData = getDataFromLocalStorage("blogData");
        const categoriesData = getDataFromLocalStorage("categoriesData");
        const Teams = getDataFromLocalStorage("teams");
        setWebData(customizationData[2]);
        setPageOrder(customizationData[0].newPageOrder);
        setFetchedData(customizationData);
        setLoading(false);

        setProjects(projectsData);
        setBlogData(blogData);
        setCategories(categoriesData);
        setTeams(Teams);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (webData) {
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      metaDescriptionTag.content = webData.Description;

      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = webData.FavIcon;
      document.title = webData.WebsiteName;
      const root = document.documentElement;
      root.style.setProperty("--buttons", webData.Colors.ButtonColors);
      root.style.setProperty("--text", webData.Colors.Text);
      root.style.setProperty("--borders", webData.Colors.Borders);
      root.style.setProperty("--icons", webData.Colors.Icons);
      root.style.setProperty("--BGColor", webData.BGColor);
      root.style.setProperty("--Lines", webData.Colors.Lines);
      root.style.setProperty("--LinkLines", webData.Colors.LinkLines);
      root.style.setProperty("--gradientColor1", webData.Colors.gradientColor1);
      root.style.setProperty("--gradientColor2", webData.Colors.gradientColor2);
      root.style.setProperty(
        "--sidePagesHeaderColor",
        webData.Colors.sidePagesHeaderColor
      );
      root.style.setProperty("--Font", webData.Font);
    }
  }, [webData]);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div
      className="App"
      style={{ backgroundColor: webData?.BGColor && webData?.BGColor }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Loading loading={loading} />
      {loading ? (
        ""
      ) : (
        <>
          {!agreeCookies && <CookiePopup />}

          <Nav
            screenWidth={width}
            Tabs={FetchedData && FetchedData[1]}
            Logo={webData.Logo}
            setActiveServicePage={setActiveServicePage}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeaderMainPage
                    screenWidth={width}
                    ServerData={FetchedData[0].Header}
                  />
                  <MainPage
                    width={width}
                    FetchedData={FetchedData}
                    Tabs={FetchedData[1]}
                    Projects={Projects}
                    PageOrder={PageOrder}
                  />
                </>
              }
            ></Route>
            <Route
              path="/Blog"
              element={
                <>
                  <BasicHeader
                    screenWidth={width}
                    BottomTitle={BlogData ? BlogData[0].Description : ""}
                    HeaderTitle={BlogData ? BlogData[0].Title : ""}
                  />
                  <BlogHome users={users} />
                </>
              }
            ></Route>
            <Route path="/Portal" element={<Portal />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route
              path="/ArticleBuilder"
              element={
                <>
                  <BasicHeader
                    screenWidth={width}
                    HeaderTitle={"Share Your Story..."}
                  />
                  <ArticleBuilder Categories={Categories} />
                </>
              }
            />
            <Route path="*" element={<NotFound />}></Route>
            <Route
              path="/:NAME"
              element={
                <>
                  <Redirect
                    screenWidth={width}
                    data={FetchedData[1]}
                    Projects={Projects}
                    Teams={Teams}
                    LandingPageData={FetchedData}
                    activeServicePage={activeServicePage}
                    setActiveServicePage={setActiveServicePage}
                  />
                </>
              }
            ></Route>
            <Route
              path="Blog/:ID"
              element={
                <>
                  <BasicHeader
                    screenWidth={width}
                    BottomTitle={BlogData ? BlogData[0].Description : ""}
                    HeaderTitle={BlogData ? BlogData[0].Title : ""}
                  />
                  <ArticlePage Users={users} width={width} />
                </>
              }
            ></Route>
            <Route
              path="Blog/Author/:ID"
              element={
                <>
                  <BlogAuthor
                    width={width}
                    Users={users}
                    Categories={Categories}
                  />
                </>
              }
            ></Route>
            <Route
              path="Application/:NAME"
              element={
                <>
                  <Template15 Data={FetchedData[1]} screenWidth={width} />
                </>
              }
            ></Route>
            <Route
              path="Application"
              element={
                <>
                  <Template15 Data={FetchedData[1]} screenWidth={width} />
                </>
              }
            ></Route>
            <Route
              path="Dashboard/Member/:ID"
              element={<EditMember AllData={Teams} />}
            ></Route>
            <Route path="Dashboard/Article/:ID" element={<EditBlog />}></Route>
            <Route
              path="/Member/:ID"
              element={
                <>
                  <ViewMember AllData={Teams} Tabs={FetchedData[1]} />
                </>
              }
            ></Route>
            <Route path="/Dashboard/User/:ID" element={<UserDetails />}></Route>
            <Route
              path="Blog/Category/:ID"
              element={
                <>
                  <BlogCategory
                    title={BlogData[0].Title}
                    width={width}
                    Categories={Categories}
                    Users={users}
                  />
                </>
              }
            ></Route>
          </Routes>
          <Footer
            Logo={webData.Logo}
            backgroundColor={webData?.Colors?.FooterBackground}
            ServerData={FetchedData[0].FooterData}
            Tabs={FetchedData[1]}
            FooterLogo={webData.FooterLogo}
          />
        </>
      )}
    </div>
  );
}

export default App;
