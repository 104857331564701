import React, { useState } from "react";
import "../Templates.css";
import ViewJob from "../../Cards/ViewJob/ViewJob";
import "./Template2.css";
import arrowLeft from "../../../assets/left-arrow.png";
import { Link } from "react-router-dom";

const Template2 = ({ Data }) => {
  const renderJobs = Data.jobs.map((job) => {
    return <ViewJob job={job} key={job.id} />;
  });
  return (
    <>
      <div className="Header-Career">
        <img src={Data.BG} />
        {Data.Title && <h1>{Data.Title}</h1>}
        <div className="overlay"></div>
      </div>
      <div
        className="TemplateGeneral"
        style={{ padding: "0", position: "relative", top: "-129px" }}
      >
        {Data.SubTitle && (
          <h2 style={{ textAlign: "center", margin: "auto" }}>
            {Data.SubTitle}
          </h2>
        )}
        {Data.Para && <p style={{ textAlign: "center" }}>{Data.Para}</p>}
        <div className="CareerContent">
          <div className="ViewJobs">{renderJobs}</div>
        </div>
        <div className="BelowJobs">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Data.BelowJobsTitle && <h3>{Data.BelowJobsTitle}</h3>}
            {Data.BelowJobsPara && <p>{Data.BelowJobsPara}</p>}
          </div>
          <Link to={"/Application"} style={{ textDecoration: "none" }}>
            <button className="Button">
              Apply
              <img src={arrowLeft} style={{ width: "24px", height: "24px" }} />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Template2;
