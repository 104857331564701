import React, { useState, useEffect, useRef } from "react";
import { CreateToast } from "../../../../App";
import { UPLOADPHOTO } from "../../../../server";
import DataTable from "react-data-table-component";

import Input from "../../../Input/Input";
import Upload from "../../../../assets/upload.png";
import sortBy from "sort-by";
import MyModal from "../../../PopUps/Confirm/Confirm";
import Template8 from "./Template8";

const Template11 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const [photoUploaded, setPhotoUploaded] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [NewCard, setNewCard] = useState({
    subTitle: "",
    Description: "",
    id: "",
  });
  const firstRender = useRef(true);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handlePrimaryAction = () => {
    if (data.Cards?.length > 0) {
      setData((prev) => {
        return { ...prev, Cards: [...prev.Cards, NewCard] };
      });
    } else {
      setData((prev) => {
        return { ...prev, Cards: [NewCard] };
      });
    }

    setNewCard({ subTitle: "", Description: "", id: "" });
    handleCloseModal();
  };

  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  useEffect(() => {
    let id;
    data.Cards?.sort(sortBy("id"));
    data.Cards?.forEach((category) => {
      id = +category.id + 1;
    });
    setNewCard((prev) => {
      return { ...prev, id: id ? id : 0 };
    });
  }, [data]);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.subTitle,
      sortable: true,
      center: true,
      width: "200px",
    },

    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
      center: true,
    },
    {
      name: "Options",
      selector: (row) => row.Options,
      sortable: true,
      center: true,
      width: "200px",
    },
  ];
  const DeleteCard = (id) => {
    const NewCards = data.Cards?.filter((Card) => {
      return Card.id !== id;
    });
    setData((prev) => {
      return { ...prev, Cards: NewCards };
    });
  };
  const tableData = data.Cards?.map((Card) => {
    const handleChange = (e) => {
      // eslint-disable-next-line no-debugger
      debugger;
      const { name, value } = e.target;
      let oldData = data.Cards;
      let newData = oldData.map((oldCard) => {
        if (oldCard.id === Card.id) {
          return {
            ...oldCard,
            [name]: value,
          };
        } else {
          return oldCard;
        }
      });
      setData((prev) => ({ ...prev, Cards: newData }));
    };

    return {
      id: Card.id,
      Description: (
        <Input
          textarea={true}
          name="Description"
          onChangeFunction={handleChange}
          value={Card.Description}
          customWidth="500px"
        />
      ),
      subTitle: (
        <Input
          name="subTitle"
          value={Card.subTitle}
          onChangeFunction={handleChange}
        />
      ),
      Options: (
        <>
          <button
            className="Button Danger"
            onClick={() => {
              DeleteCard(Card.id);
            }}
          >
            Delete
          </button>
        </>
      ),
    };
  });
  const uploadPhoto = async (path, photo) => {
    CreateToast("uploading photo", "info", 2000);
    const url = await UPLOADPHOTO(path, photo);
    CreateToast("photo uploaded", "success");
    return url;
  };

  const handlePhotoUpload = async (name, path, photo, setData) => {
    setPhotoUploaded(false);
    const url = await uploadPhoto(path, photo);
    setData((prev) => ({ ...prev, [name]: url }));
    setPhotoUploaded(true);
  };

  const handleInput = async (e) => {
    const { name, value, files } = e.target;
    const basePath = `/customization/SidePages/${BackEndName}`;

    if (files && files[0]) {
      switch (name) {
        case "firstSectionImage":
          await handlePhotoUpload(
            name,
            `${basePath}/firstSectionImage.jpg`,
            files[0],
            setData
          );
          break;
        case "secondSectionFirstImage":
          await handlePhotoUpload(
            name,
            `${basePath}/secondSectionFirstImage.jpg`,
            files[0],
            setData
          );
          break;
        case "secondSectionSecondImage":
          await handlePhotoUpload(
            name,
            `${basePath}/secondSectionSecondImage.jpg`,
            files[0],
            setData
          );
          break;
        case "secondSectionThirdImage":
          await handlePhotoUpload(
            name,
            `${basePath}/secondSectionThirdImage.jpg`,
            files[0],
            setData
          );
          break;
        case "Icon":
          await handlePhotoUpload(
            name,
            `${basePath}/Icon.jpg`,
            files[0],
            setData
          );
          break;
        default:
          break;
      }
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const deleteImage = (image) => {
    CreateToast("deleting");
    setData((prev) => ({ ...prev, [image]: "" }));
    CreateToast("deleted", "success");
  };
  return (
    <div className="DataEntry Hosting Template11">
      {showModal && (
        <MyModal
          className="Confirm"
          show={showModal}
          handleClose={handleCloseModal}
          title="AddCard"
          primaryButtonText={`Add`}
          handlePrimaryAction={handlePrimaryAction}
        >
          <>
            <Input
              label="SubTitle"
              type="text"
              id="subTitle"
              name="subTitle"
              value={NewCard.subTitle}
              onChangeFunction={(event) => {
                const { name, value } = event.target;

                setNewCard((prev) => {
                  return { ...prev, [name]: value };
                });
              }}
            />
            <Input
              textarea={true}
              label="Description"
              id="Description"
              name="Description"
              value={NewCard.Description}
              onChangeFunction={(event) => {
                const { name, value } = event.target;
                setNewCard((prev) => {
                  return { ...prev, [name]: value };
                });
              }}
            />
          </>
        </MyModal>
      )}{" "}
      <div className="boxWrapper">
        <h4 className="BoxTitle">Media</h4>

        <div className="formItem">
          <span>Icon:</span>
          <label htmlFor="Icon">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>

          <input
            type="file"
            accept="image/*"
            hidden
            id="Icon"
            name="Icon"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Name in navigation "
          type="text"
          id="PageName"
          name="PageName"
          required={true}
          value={data.PageName}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Page Description: "
          type="text"
          id="Description"
          name="Description"
          required={true}
          value={data.Description}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Header Title"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <Input
          label="Short Description"
          type="text"
          id="BottomTitle"
          name="BottomTitle"
          required={true}
          value={data.BottomTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">First Section</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Header"
            type="text"
            id="Section1Header"
            name="Section1Header"
            required={true}
            value={data.Section1Header}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Text"
            type="text"
            id="Section1Text"
            name="Section1Text"
            required={true}
            value={data.Section1Text}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Button Text"
            required={true}
            id="Button1Text"
            name="Button1Text"
            value={data.Button1Text}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Button Link"
            required={true}
            id="Button1Link"
            name="Button1Link"
            value={data.Button1Link}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="UploadWrapper">
          <span>Section image: </span>
          <label htmlFor="firstSectionImage">
            <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
          </label>
          <input
            type="file"
            hidden
            accept="image/*"
            required
            id="firstSectionImage"
            name="firstSectionImage"
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Second Section</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Header"
            type="text"
            id="Section2Header"
            name="Section2Header"
            required={true}
            value={data.Section2Header}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Sub Header"
            type="text"
            id="Section2SubHeader"
            name="Section2SubHeader"
            required={true}
            value={data.Section2SubHeader}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Text"
            type="text"
            id="Section2Text"
            name="Section2Text"
            required={true}
            value={data.Section2Text}
            onChangeFunction={handleInput}
          />
        </div>

        <div className="UploadWrapper">
          <div className="FormItem">
            <span>First image: </span>
            <label htmlFor="secondSectionFirstImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="secondSectionFirstImage"
              name="secondSectionFirstImage"
              onChange={handleInput}
            />
            {data.secondSectionFirstImage && (
              <button
                className="Button"
                onClick={() => {
                  deleteImage("secondSectionFirstImage");
                }}
              >
                delete
              </button>
            )}
          </div>
          <div className="FormItem">
            <span>Second image: </span>
            <label htmlFor="secondSectionSecondImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="secondSectionSecondImage"
              name="secondSectionSecondImage"
              onChange={handleInput}
            />
            {data.secondSectionSecondImage && (
              <button
                className="Button"
                onClick={() => {
                  deleteImage("secondSectionSecondImage");
                }}
              >
                delete
              </button>
            )}
          </div>
          <div className="FormItem">
            <span>Third image: </span>
            <label htmlFor="secondSectionThirdImage">
              <img src={Upload} style={{ width: "25px", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              hidden
              accept="image/*"
              required
              id="secondSectionThirdImage"
              name="secondSectionThirdImage"
              onChange={handleInput}
            />
            {data.secondSectionThirdImage && (
              <button
                className="Button"
                onClick={() => {
                  deleteImage("secondSectionThirdImage");
                }}
              >
                delete
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">Third Section</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Header"
            type="text"
            id="Section3Header"
            name="Section3Header"
            required={true}
            value={data.Section3Header}
            onChangeFunction={handleInput}
          />
        </div>
        <button
          className="Button Add"
          style={{ margin: "0px auto" }}
          onClick={handleShowModal}
        >
          Add Card
        </button>
        <DataTable
          className="Table  animate__fadeIn"
          style={{ animationDelay: ".4s" }}
          theme="light"
          highlightOnHover
          columns={columns}
          data={tableData}
        />
      </div>
      <div className="boxWrapper">
        <h4 className="BoxTitle">fourth Section</h4>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Section Header"
            type="text"
            id="Section4Header"
            name="Section4Header"
            value={data.Section4Header}
            onChangeFunction={handleInput}
          />
        </div>
        <Template8
          Data={data}
          UpdateData={UpdateData}
          BackEndName={BackEndName}
        />
      </div>
      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            if (!photoUploaded) {
              CreateToast("uploading Photo,please wait...", "error", 2000);
              return;
            }
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template11;
