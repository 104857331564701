import React from "react";
import "./TeamCard.css";
import { Link } from "react-router-dom";

const TeamCard = ({ Data, delayString, admin }) => {
  return (
    <div style={{ animationDelay: delayString }} className="TeamCard">
      <div className="Photo PhotoEffect">
        <Link
          style={{ textDecoration: "none" }}
          to={admin ? "" : `/Member/${Data.id}`}
        >
          <img src={Data.image} alt={Data.name} />
        </Link>
      </div>
      <div className="Details">
        <div className="Name">
          <Link
            style={{ textDecoration: "none" }}
            to={admin ? "" : `/Member/${Data.id}`}
          >
            <p>{Data.name}</p>
          </Link>
        </div>

        <div className="Extra">
          <div className="text-container">{Data.Role}</div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
