//NOT USED
import React, { useEffect, useState } from "react";
import TeamCard from "../../Cards/Team/TeamCard";

import "./Template8.css";
import sortBy from "sort-by";
const Template8 = ({ Teams }) => {
  const renderTeam = Teams.sort(sortBy("Order")).map((Person, index) => {
    if (Person) {
      const delayString = (index * 0.05).toString() + "s";

      return (
        <TeamCard
          key={Person.id}
          Data={Person}
          delayString={delayString}
          admin={false}
        />
      );
    }
  });

  return (
    <div className="TemplateGeneral">
      <ul className="Team-wrapper">{renderTeam}</ul>
    </div>
  );
};

export default Template8;
