import React from "react";
import "./loading.css";
import loadingGIF from "../../assets/loadingv2.gif";
const Loading = ({ loading }) => {
  return (
    <div className={`Loading-wrapper ${loading ? "" : "FADE"}`}>
      <img src={loadingGIF} />
    </div>
  );
};
export default Loading;
