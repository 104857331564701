import React from "react";
import "./Section4.css";
import rightArrow from "../../../assets/gradientArrow.png";
import { Link } from "react-router-dom";
const Section4 = (props) => {
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <section
      className="Section4"
      style={{
        backgroundColor: props.ServerData.BGColor && props.ServerData.BGColor,
      }}
    >
      <div className="Left">
        {props.ServerData.Tag && (
          <div
            className="Tag"
            style={{
              backgroundColor:
                getDataFromLocalStorage("TagBG") &&
                getDataFromLocalStorage("TagBG"),
            }}
          >
            <span>{props.ServerData.Tag}</span>
          </div>
        )}
        {props.ServerData.title && <h2>{props.ServerData.title}</h2>}
        {props.ServerData.paragraph && (
          <p id="sec4Para" data-aos="fade-left">
            {props.ServerData.paragraph}
          </p>
        )}
        <div
          className="Button Empty "
          data-aos="fade-left"
          data-aos-anchor="#sec4Para"
        >
          <Link
            style={{ textDecoration: "none" }}
            to={props.ServerData.ButtonLink}
          >
            <span>
              {props.ServerData.ButtonText}
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5L6.5 6.5L1.5 11.5"
                  stroke="url(#paint0_linear_264_60)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />

                <defs>
                  <linearGradient
                    id="paint0_linear_264_60"
                    x1="0.431452"
                    y1="6.61111"
                    x2="6.39919"
                    y2="6.61111"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      stopColor={getDataFromLocalStorage("gradientColor1")}
                    />
                    <stop
                      offset="1"
                      stopColor={getDataFromLocalStorage("gradientColor2")}
                    />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          </Link>
        </div>
      </div>
      {props.screenWidth > 1000 && (
        <div className="Right">
          {props.ServerData.secondImage && (
            <div
              className="Effect Second "
              data-aos="fade-in"
              data-aos-anchor=".Section4"
              data-aos-delay="300"
            >
              <img src={props.ServerData.secondImage} />
            </div>
          )}
          {props.ServerData.thirdImage && (
            <div
              className="Effect First"
              data-aos="fade-in"
              data-aos-anchor=".Section4"
              data-aos-delay="500"
            >
              <img src={props.ServerData.thirdImage} />
            </div>
          )}
          {props.ServerData.URL && (
            <div className="Main" data-aos="fade-in">
              <img src={props.ServerData.thumbnail} />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Section4;
