import React from "react";
import "./ArticlePreview.css";

import { Link } from "react-router-dom";
const ArticlePreview = ({ Article, delay }) => {
  const delayString = delay.toString() + "s";
  return (
    <div className="ArticlePreview" style={{ animationDelay: delayString }}>
      <div className="TopPart">
        <img src={Article.thumbnail} />
      </div>
      <div className="bottomPART">
        <span className="DateAdded">{Article.DateAdded}</span>
        <div className="Title-wrapper">{Article.Title}</div>
        <div className="Description">{Article.Description}</div>
        <Link
          style={{ textDecoration: "none" }}
          to={`/Blog/${Article.Title}`}
          className="Link"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default ArticlePreview;
