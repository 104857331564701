import React, { useEffect, useState } from "react";
import "./Nav.css";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";
import { Link } from "react-router-dom";
const Nav = ({ screenWidth, Tabs, Logo, setActiveServicePage }) => {
  const [Color, setColor] = useState(false);
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  const ChangeColor = () => {
    if (screenWidth < 500) {
      if (window.scrollY >= 20) {
        setColor(true);
      } else {
        setColor(false);
      }
    } else {
      if (window.scrollY >= 60) {
        setColor(true);
      } else {
        setColor(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      ChangeColor();
    });
  }, []);

  return (
    <div
      className={`NavContainer ${
        getDataFromLocalStorage("NavTheme") === "Second" ? "Second" : ""
      } ${Color ? "NavContainer-bg" : ""}`}
    >
      <div className="Logo">
        <Link style={{ textDecoration: "none" }} to={"/"}>
          <img src={Logo} />
        </Link>
      </div>
      {screenWidth > 1000 ? (
        <NavDesktop
          screenWidth={screenWidth}
          Tabs={Tabs}
          setActiveServicePage={setActiveServicePage}
        />
      ) : (
        <NavMobile
          screenWidth={screenWidth}
          Tabs={Tabs}
          setActiveServicePage={setActiveServicePage}
        />
      )}
    </div>
  );
};

export default Nav;
