import React, { useRef, useState } from "react";
import "./WhyUs.css";
import useElementVisibility from "../../hooks/useElementVisibility";

const WhyUs = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);

  const isVisible = useElementVisibility(ref);
  return (
    <div
      ref={ref}
      className={`WhyUs-Card ${isHovered ? "active" : ""} ${
        isVisible ? "animate__animated animate__slow  animate__fadeIn" : ""
      }`}
      style={{
        cursor: props.Data.Link ? "pointer" : "",
        animationDelay: ".2s",
      }}
      onClick={() => {
        props.Data.Link && (window.location.href = props.Data.Link);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="cardBG"></div>
      {props.Data.iconURL && (
        <div className="IMGWrapper">
          <img src={props.Data.iconURL} alt="icon" />
        </div>
      )}

      {props.Data.title && <h4>{props.Data.title}</h4>}
      {props.Data.description && <span>{props.Data.description}</span>}
    </div>
  );
};

export default WhyUs;
