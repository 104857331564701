import React, { useState, useEffect, useRef } from "react";

import Input from "../../../Input/Input";

const Template13 = ({ Data, UpdateData, BackEndName, setEdited, edited }) => {
  const [data, setData] = useState(Data);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      // Skip the first render
      firstRender.current = false;
    } else {
      setEdited(true);
    }
  }, [data]);
  const handleInput = async (e) => {
    const { name, value } = e.target;

    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <div className="DataEntry Hosting">
      <div className="boxWrapper">
        <h4 className="BoxTitle">General Data</h4>
        <span style={{ margin: "20px" }}>
          To hide a page just leave the <strong>Page URL</strong> field empty
        </span>
        <Input
          label="Page URL"
          type="text"
          id="PageURL"
          name="PageURL"
          value={data.PageURL}
          onChangeFunction={handleInput}
          customWidth="70%"
        />

        <Input
          label="Header Title"
          type="text"
          id="HeaderTitle"
          name="HeaderTitle"
          value={data.HeaderTitle}
          onChangeFunction={handleInput}
          customWidth="70%"
        />
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Bottom Title"
            type="text"
            id="BottomTitle"
            name="BottomTitle"
            required={true}
            value={data.BottomTitle}
            onChangeFunction={handleInput}
          />
        </div>
        <div className="FormItem" style={{ width: "70%" }}>
          <Input
            label="Page Header Title"
            type="text"
            id="PageTitle"
            name="PageTitle"
            required={true}
            value={data.PageTitle}
            onChangeFunction={handleInput}
          />
        </div>
      </div>

      <div className={`SubmitWrapper ${edited ? "fixed" : ""}`}>
        <button
          className="Button View"
          id="Submit"
          onClick={() => {
            UpdateData(BackEndName, data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Template13;
